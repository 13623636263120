import gql from 'graphql-tag'

import { TRANSACTION_DATA } from './Transaction'
import { REGISTRATION_DATA } from './Registration'

const FAMILY_DATA = gql`
    fragment FamilyParts on Family {
        id
        name
        createdAt
    }
`

const PERSON_DATA = gql`
    fragment PersonParts on Person {
        id
        link
        firstName
        lastName
        email
        phone
        phoneExt
        address
        address2
        city
        province
        country
        postal
        dob
        age
        gender
        number
        ooc
        concerns
        needs
        marketedBy
        createdAt
        status
        # updatedAt
    }
`

export const SEARCH_FAMILIES = gql`
query SearchFamilies($term: String!) {
    families: searchFamilies(term: $term) {
        id
        name
        members {
            id
            firstName
            lastName
        }
    }
}
`

export const GET_ACCOUNT_TRANSACTIONS = gql`
    query GetAccountTransactions($id: ID!) {
        account(id: $id) {
            ...FamilyParts
            transactions {
                ...TransactionParts
            }
        }
    }
    ${ FAMILY_DATA }
    ${ TRANSACTION_DATA }
`

export const GET_ACCOUNT_REGISTRATIONS = gql`
    query GetAccountRegistrations($id: ID!) {
        account(id: $id) {
            ...FamilyParts
            registrations {
                ...RegistrationParts
            }
        }
    }
    ${ FAMILY_DATA }
    ${ REGISTRATION_DATA }
`

export const GET_ACCOUNT = gql`
    query GetAccount($id: ID!) {
        account(id: $id) {
            ...FamilyParts
            members {
                ...PersonParts
            }
            totalSales
            accountBalance
            revenue
            npsRating
            creditsApplied
            isActive
        }
    }
    ${ FAMILY_DATA }
    ${ PERSON_DATA }
`

export const GET_PERSON = gql`
    query GetPerson($id: ID!) {
        person(id: $id) {
            ...PersonParts
        }
    }
    ${ PERSON_DATA }
`

export const AUTHENTICATE = gql`
    mutation Authenticate($payload: AuthPayload!) {
        authenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`

export const GET_PAGINATED_ACCOUNTS = gql`
    query Accounts($paginate: Paginate! $term: String $filters: Filters) {
        accounts(paginate: $paginate term: $term filters: $filters) {
            edges {
                node {
                    id
                    name
                    createdAt
                    addresses {
                        city
                        province
                        country
                    }
                    isActive
                }
            }
            cursors {
                before
                after
                hasNext
                hasPrevious
            }
        }
    }
`

export const CREATE_ACCOUNT = gql`
    mutation CreateAccount($payload: CreateAccountPayload!) {
        createAccount(payload: $payload) {
            id
        }
    }
`

export const SUBSCRIBE_ACCOUNT_CREATED_IN_PERSON = gql`
    subscription onAccountCreatedInPerson {
        accountCreatedInPerson {
            id
            name
            createdAt
            addresses {
                city
                province
                country
            }
        }
    }
`

export const GET_ACCOUNT_NOTES = gql`
    query GetAccountNotes($accountId: ID!) {
        accountNotes(accountId: $accountId) {
            id
            note
            user {
                id
                firstName
                lastName
                profileImage
            }
            createdAt
        }
    }
`

export const ADD_ACCOUNT_NOTE = gql`
    mutation AddAccountNote($payload: AddAccountNotePayload!) {
        addAccountNote(payload: $payload) {
            id
            note
            user {
                id
                firstName
                lastName
                profileImage
            }
            createdAt
        }
    }
`

export const CHECK_ACCOUNT_EMAIL = gql`
    query CheckAccountEmail($email: String!) {
        checkAccountEmail(email: $email)
    }
`

export const SET_ACCOUNT_CREDITS_APPLIED = gql`
    mutation SetAccountCreditsApplied($id: ID!) {
        setAccountCreditsApplied(id: $id) {
            id
            creditsApplied
        }
    }
`

export const SET_ACCOUNT_STATUS = gql`
    mutation SetAccountStatus($id: ID! $isActive: Boolean!) {
        setAccountStatus(id: $id isActive: $isActive) {
            id
            isActive
        }
    }
`

export const GET_COVID_SCREENING = gql`
    query GetCovidScreening($accountId: ID! $today: String!) {
        covidScreening: covidScreeningAdmin(accountId: $accountId today: $today)
    }
`

export const GET_ACCOUNT_GUARDIANS = gql`
    query GetGuardians($accountId: ID!) {
        guardians(accountId: $accountId) {
            id
            guardians {
                id
                firstName
                lastName
                relationship
            }
            authorizedPickup {
                id
                firstName
                lastName
                relationship
            }
        }
    }
`