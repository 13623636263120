import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_PAGINATED_EMAILS, GET_EMAIL, SET_EMAIL_READ } from '../../operations/Email'
import Card from '../../components/Card'
import Scroll from '../../components/Scroll'
import NoData from '../../components/NoData'
import moment from 'moment'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Grid from '../../components/Grid'
import { Link } from '@reach/router'

const LIMIT = 250

export default function Inbox({ uuid }) {

    if(uuid) return <Email uuid={ uuid } />

    return <EmailList />

}

function EmailList() {

    // const [ showFilters, setShowFilters ] = useState(false)
    const [ filters, 
        // setFilters 
    ] = useState({})

    const [ref, inView] = useInView({
        threshold: 0.1,
        rootMargin: '0px'
    })

    const [searchTerm, 
        // setSearchTerm
    ] = useState()

    const [ setRead ] = useMutation(SET_EMAIL_READ)

    const { loading, error, data, fetchMore } = useQuery(GET_PAGINATED_EMAILS, {
        variables: {
            paginate: {
                first: LIMIT //filters ? 25 : LIMIT
            },
            term: searchTerm,
            filters
        },
        pollInterval: 60000
    })

    // console.log(data)

    const { emails } = data || {}

    useEffect(() => {
        if (inView) {
            fetchMore({
                variables: {
                    paginate: {
                        first: LIMIT,
                        after: emails.cursors.after
                    },
                    term: searchTerm
                },

                updateQuery: (prevResult, { fetchMoreResult }) => {
                    
                    const newEdges = fetchMoreResult.emails.edges
                    const cursors = fetchMoreResult.emails.cursors

                    return newEdges.length ? {
                        emails: {
                            __typename: prevResult.emails.__typename,
                            edges: [...prevResult.emails.edges, ...newEdges],
                            cursors
                        }
                    } : prevResult

                }
            })
        }
    }, [inView, fetchMore, emails, searchTerm])

    if (loading) return 'Loading...'
    if (error) return 'Error...'

    return (
        <>
            <Scroll>

                {

                    emails.edges.length > 0 ? (
                        emails.edges.map(({node}) => {

                            // const address = node.addresses[0]

                            const { 'From': from } = node.headers.find((header) => Object.keys(header)[0] === 'From')

                            return (
                                <Link 
                                    to={`${node.uuid}`} key={ node.uuid } 
                                    style={{ 
                                        fontWeight: node.isRead ? 'normal' : 'bold', 
                                        padding: '1.3rem .5rem', 
                                        borderBottom: '1px solid #fafafa',
                                        margin: '0 -2rem',
                                        display: 'block',
                                        textDecoration: 'none',
                                        color: 'rgba(0,0,0,0.9)'
                                    }}
                                    onClick={() => {

                                        setRead({
                                            variables: {
                                                uuid: node.uuid
                                            }
                                        })

                                    }}
                                >
                                    <Grid
                                        style={{
                                            gridTemplateColumns: `
                                                minmax(260px, 1fr)
                                                minmax(300px, 5fr)
                                                minmax(100px, .2fr)
                                            `,
                                            gridGap: '1rem',
                                            padding: '0 1rem'
                                        }}
                                    >
                                        <div>{ from.replace(/<.*>/, '') }</div>
                                        <div>{ node.subject }</div>
                                        <div style={{ textAlign: 'right' }}>{ moment(Number(node.createdAt)).isSame(moment(), 'day') ? moment(Number(node.createdAt)).format('LT') : moment(Number(node.createdAt)).format('ll') }</div>
                                    
                                    </Grid>
                                </Link>
                            )
                        })
                    ) : (
                        <NoData>Sorry, but we couldn't find any emails matching that search term.</NoData>
                    )
                }
                { emails.cursors.hasNext && <div ref={ ref }>Loading</div> }
            </Scroll>

        </>
    )

}

function Email({ uuid }) {

    const { loading, error, data } = useQuery(GET_EMAIL, {
        variables: {
            uuid
        }
    })

    function transform(node, index) {
        // console.log(node)
        if (node.type === 'tag' && ['div','p', 'a', 'b', 'br', 'img', 'html', 'body', 'span', 'center', 'table', 'tbody', 'thead', 'tfoot', 'tr', 'td', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(node.name) === -1) return null
        if (node.type === 'tag' && ['html','body'].indexOf(node.name) !== -1) {
            node.name = 'div'
            return convertNodeToElement(node, index, transform)
        }
    }

    if (loading) return 'Loading...'
    if (error) return 'Error...'

    const { email } = data

    // console.log(email.html)

    const { 'From': from } = email.headers.find((header) => Object.keys(header)[0] === 'From')

    return (
        <>
            <Card>
                { from }<br/>
                To: { email.to[0] }
            </Card>
            { email.html ? ReactHtmlParser(email.html, { transform }) : email.text }
            {/* { email.headers.map((header,i) => <div key={i}>{ JSON.stringify(header) }</div>) } */}
        </>
    )
}