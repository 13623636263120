import React, { createContext, useContext, useState } from 'react'
import moment from 'moment'

const DateRangeContext = createContext()

const firstDayOfWeek = moment().startOf('week')
const lastDayOfWeek = moment().endOf('week')

export default function Account({ children }) {

    const [startDate, setStartDate] = useState(firstDayOfWeek)
    const [endDate, setEndDate] = useState(lastDayOfWeek)

    

    return (
        <DateRangeContext.Provider
            value={{
                startDate,
                endDate,
                setStartDate: (date) => setStartDate(date),
                setEndDate: (date) => setEndDate(date)
            }}
        >
            { children }
        </DateRangeContext.Provider>
    )

}

export const useDateRange = () => useContext(DateRangeContext)