import Form, { useForm, FormContext } from "./Form";

import Fieldset from "./Fieldset";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Search from "./Search";

import Submit, { Submit2 } from "./Submit";

import "./input.css";

export default Form;
export {
  useForm,
  FormContext,
  Fieldset,
  Input,
  Select,
  Textarea,
  Search,
  Submit,
  Submit2,
};
