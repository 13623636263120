import React, { useState } from "react";
import "./header.css";
import "./header.scss";

import { Link } from "@reach/router";
import Button from "../Button";
import { useAuthentication } from "../../hooks/authentication";
import Modal, { ModalClose } from "../Modal";
import { useNotification } from "../../hooks/notification";
import { useModal } from "../../hooks/modal";
import NoData from "../../components/NoData";
import { Icon as IconifyIcon } from "@iconify/react";
// import { useRegistration } from '../../hooks/registration'

export default function Header({ children }) {
  const [navExpanded, setNavExpanded] = useState();
  const { logout } = useAuthentication();
  const { setModal } = useModal();

  return (
    <div className="headerContainer">
      <header>
        <div className="logo">
          <Icon>send</Icon>
          Encampment
        </div>

        <div className="headerLine" />

        <Button
          unstyled
          className="hamburger"
          onClick={() => setNavExpanded(!navExpanded)}
        >
          <Icon>menu</Icon>
        </Button>

        {/* <div style={{ margin: '1rem 2rem' }}>
                    <Button
                        onClick={() => setRegistration({ isOpen: true })}
                    >
                        <Icon>add</Icon>
                        New Registration
                    </Button>
                </div> */}

        <nav className={navExpanded ? "nav--expanded" : "nav--collapsed"}>
          <NavLink onClick={() => setNavExpanded(false)} to="/">
            <Icon>speed</Icon>Dashboard
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="inbox">
            <Icon>inbox</Icon>Inbox
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="accounts">
            <Icon>supervisor_account</Icon>Accounts
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="registrations">
            <Icon>how_to_reg</Icon>Registrations
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="accommodations">
            <Icon>single_bed</Icon>Accommodations
          </NavLink>
          {/* <NavLink onClick={() => setNavExpanded(false)} to='refunds'><Icon>account_balance_wallet</Icon>Refunds</NavLink> */}
          <NavLink onClick={() => setNavExpanded(false)} to="credits">
            <Icon>account_balance_wallet</Icon>Apply credits
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="transactions">
            <Icon>receipt</Icon>Recent transactions
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="exports">
            <IconifyIcon icon="mdi:download" fontSize={24} />
            Exports
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="onsite">
            <Icon>wb_sunny</Icon>On site
          </NavLink>
          <NavLink onClick={() => setNavExpanded(false)} to="link">
            <Icon>phone_iphone</Icon>Link Devices
          </NavLink>
          <div className="navBottom">
            <button
              className="button--link"
              type="button"
              onClick={() => setModal("NOTIFICATIONS")}
            >
              <Icon>notifications</Icon>Notifications
              <Notifications />
            </button>
            <NavLink onClick={() => setNavExpanded(false)} to="settings">
              <Icon>settings</Icon>Settings
            </NavLink>
            <button
              className="button--link"
              type="button"
              onClick={() => logout()}
            >
              <Icon>exit_to_app</Icon>Logout
            </button>
          </div>
        </nav>
      </header>
    </div>
  );
}

function Icon({ children }) {
  return <i className="material-icons">{children}</i>;
}

function NavLink(props) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? "current" : "",
        };
      }}
    />
  );
}

function Notifications() {
  const { notifications, removeNotification } = useNotification();
  const { modal, setModal } = useModal();

  return (
    <>
      {notifications.length > 0 && (
        <div className="notificationBubble">{notifications.length}</div>
      )}
      {modal === "NOTIFICATIONS" && (
        <Modal>
          <ModalClose />
          <h1>Notifications</h1>
          {notifications.map((notification, i) => {
            const account = notification.accountCreatedInPerson;
            return (
              <Link
                key={i}
                to={`/account/${account.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  removeNotification(i);
                  setModal();
                }}
              >
                Account created: {account.name}
              </Link>
            );
          })}
          {notifications.length < 1 && (
            <div
              style={{
                position: "relative",
                minHeight: "200px",
                minWidth: "500px",
              }}
            >
              <NoData>No notifications to display.</NoData>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
