import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GET_PROGRAM_CATEGORIES_TODAY,
  GET_PROGRAM_TODAY,
} from "operations/Program";
import Card, { CardTitle } from "components/Card";
import { DateTime } from "luxon";
import { useInterval } from "hooks/interval";

import "./onSite.scss";
import { CHECK_IN_OUT, REVERT_CHECK_IN_OUT } from "operations/Registration";

export default function OnSite() {
  const { error, loading, data } = useQuery(GET_PROGRAM_CATEGORIES_TODAY, {
    variables: { today: DateTime.now().toISODate() },
  });

  if (error) return "Error loading data";
  if (loading) return "Loading...";

  return (
    <>
      <h1>On site</h1>
      {data &&
        data.programCategories &&
        data.programCategories.map((category) => (
          <Program key={category.id} category={category} />
        ))}
    </>
  );
}

function Program({ category }) {
  const { data } = useQuery(GET_PROGRAM_TODAY, {
    variables: {
      filter: { program: category.id },
      today: DateTime.now().toISODate(),
    },
    pollInterval: 30000,
  });

  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(DateTime.now());

  useInterval(() => {
    setTime(DateTime.now());
  }, 30000);

  const notArrivedCount =
    data &&
    data.registrationsToday &&
    data.registrationsToday.reduce(
      (total, person) => (!person.isCheckedIn ? (total += 1) : total),
      0
    );
  const checkedInCount =
    data &&
    data.registrationsToday &&
    data.registrationsToday.reduce(
      (total, person) =>
        person.isCheckedIn && !person.isCheckedOut ? (total += 1) : total,
      0
    );
  const checkedOutCount =
    data &&
    data.registrationsToday &&
    data.registrationsToday.reduce(
      (total, person) => (person.isCheckedOut ? (total += 1) : total),
      0
    );

  const notArrived =
    data &&
    data.registrationsToday &&
    data.registrationsToday.filter((registration) => !registration.isCheckedIn);
  const checkedIn =
    data &&
    data.registrationsToday &&
    data.registrationsToday.filter(
      (registration) => registration.isCheckedIn && !registration.isCheckedOut
    );
  const checkedOut =
    data &&
    data.registrationsToday &&
    data.registrationsToday.filter((registration) => registration.isCheckedOut);

  return (
    <>
      <Card
        key={category.id}
        style={{
          display: "grid",
          gridTemplateColumns: "auto repeat(3, 120px)",
        }}
        onClick={() => setOpen(!open)}
      >
        <CardTitle title="Program">{category.name}</CardTitle>
        <CardTitle
          title="Has not arrived"
          right
          className={`${
            time.hour >= 9 && notArrivedCount > 0 ? "checkIn--late" : ""
          }`}
        >
          {notArrivedCount}
        </CardTitle>
        <CardTitle
          title="Checked in"
          right
          className={`${
            time.hour >= 17 && time.minute > 10 && checkedInCount > 0
              ? "checkIn--late"
              : ""
          }`}
        >
          {checkedInCount}
        </CardTitle>
        <CardTitle title="Checked out" right>
          {checkedOutCount}
        </CardTitle>
      </Card>
      {open && data && data.registrationsToday && (
        <div className="tw-p-8">
          {notArrived.length > 0 && (
            <h2 className="tw-text-4xl tw-text-blue-600">Not Arrived</h2>
          )}
          {notArrived.map((registration, i) => {
            return (
              <Person
                key={i}
                registration={registration}
                type="NOT_ARRIVED"
                categoryId={category.id}
              />
            );
          })}
          {checkedIn.length > 0 && (
            <h2 className="tw-text-4xl tw-text-blue-600">Checked In</h2>
          )}
          {checkedIn.map((registration, i) => {
            return (
              <Person
                key={i}
                registration={registration}
                type="CHECKED_IN"
                categoryId={category.id}
              />
            );
          })}
          {checkedOut.length > 0 && (
            <h2 className="tw-text-4xl tw-text-blue-600">Checked Out</h2>
          )}
          {checkedOut.map((registration, i) => {
            return (
              <Person
                key={i}
                registration={registration}
                type="CHECKED_OUT"
                categoryId={category.id}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

function Person({ registration, type, categoryId }) {
  const { person } = registration;

  const [checkInOut, { loading: loadingCheckInOut }] =
    useMutation(CHECK_IN_OUT);
  const [revertCheckInOut, { loading: loadingRevertCheckInOut }] =
    useMutation(REVERT_CHECK_IN_OUT);

  async function handleCheckInOut(type) {
    await checkInOut({
      variables: {
        payload: {
          registrationId: registration.id,
          type: type,
          programId: categoryId,
          isOvernightCamp: registration.isOvernightCamp,
        },
      },
      refetchQueries: [
        {
          query: GET_PROGRAM_TODAY,
          variables: {
            filter: { program: categoryId },
            today: DateTime.now().toISODate(),
          },
        },
      ],
    });
  }
  async function handleRevertCheckInOut(type) {
    await revertCheckInOut({
      variables: {
        payload: {
          registrationId: registration.id,
          type: type,
          programId: categoryId,
          isOvernightCamp: registration.isOvernightCamp,
        },
      },
      refetchQueries: [
        {
          query: GET_PROGRAM_TODAY,
          variables: {
            filter: { program: categoryId },
            today: DateTime.now().toISODate(),
          },
        },
      ],
    });
  }

  const buttonStyles =
    "tw-px-4 tw-py-2 tw-bg-blue-600 tw-rounded tw-text-white/80 hover:tw-bg-blue-700";
  const revertButtonStyles =
    "tw-px-4 tw-py-2 tw-bg-red-600 tw-rounded tw-text-white/80 hover:tw-bg-red-700";
  return (
    <Card className="tw-flex tw-justify-between tw-items-center">
      <CardTitle title="Name">
        {person.firstName} {person.lastName}
      </CardTitle>
      <div className="tw-flex tw-gap-4">
        {type === "NOT_ARRIVED" && (
          <button
            type="button"
            className={buttonStyles}
            onClick={() => handleCheckInOut("CHECK_IN")}
            disabled={loadingCheckInOut || loadingRevertCheckInOut}
          >
            {loadingCheckInOut || loadingRevertCheckInOut
              ? "Loading..."
              : "Check-in"}
          </button>
        )}
        {type === "CHECKED_IN" && (
          <button
            type="button"
            className={buttonStyles}
            onClick={() => handleCheckInOut("CHECK_OUT")}
            disabled={loadingCheckInOut || loadingRevertCheckInOut}
          >
            {loadingCheckInOut || loadingRevertCheckInOut
              ? "Loading..."
              : "Check-out"}
          </button>
        )}
        {type === "CHECKED_IN" && (
          <button
            type="button"
            className={revertButtonStyles}
            onClick={() => handleRevertCheckInOut("CHECK_IN")}
            disabled={loadingCheckInOut || loadingRevertCheckInOut}
          >
            {loadingCheckInOut || loadingRevertCheckInOut
              ? "Loading..."
              : "Revert Check-in"}
          </button>
        )}
        {type === "CHECKED_OUT" && (
          <button
            type="button"
            className={revertButtonStyles}
            onClick={() => handleRevertCheckInOut("CHECK_OUT")}
            disabled={loadingCheckInOut || loadingRevertCheckInOut}
          >
            {loadingCheckInOut || loadingRevertCheckInOut
              ? "Loading..."
              : "Revert Check-out"}
          </button>
        )}
      </div>
    </Card>
  );
}
