import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_ACCOUNT, GET_PERSON } from "../../operations/Account";
import { GET_REGISTRATIONS_BY_PERSON_ID } from "../../operations/Person";
import { FRIEND_REQUEST, SNACKBAR_BONUS } from "../../operations/Registration";
import Button from "../../components/Button";
import { LayoutHeader } from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import Format from "../../components/Format";
import moment from "moment";
import Card, { CardDate, CardTitle } from "../../components/Card";
import NoData from "../../components/NoData";
import Edit from "./Edit";
import { ProfilePicture } from "../../components/Profile";
import Register from "./Register";
import Modal, { ModalClose, ModalHeader } from "../../components/Modal";
import { useModal } from "../../hooks/modal";
import Notes from "../../components/Notes";
import Form, { Input, Submit } from "../../components/Form";
// import Grid from '../../components/Grid'
// import Scroll from '../../components/Scroll'

import "./person.scss";

export function Person({ accountId, personId }) {
  const { modal, setModal } = useModal();

  const {
    loading: accountLoading,
    error: accountError,
    data: accountData,
  } = useQuery(GET_ACCOUNT, { variables: { id: accountId } });
  const {
    loading: personLoading,
    error: personError,
    data: personData,
  } = useQuery(GET_PERSON, { variables: { id: personId } });

  if (accountLoading || personLoading) return "Loading...";
  if (accountError) return `Error: ${accountError.message}`;
  if (personError) return `Error: ${personError.message}`;

  const { account } = accountData;
  const { person } = personData;

  const age =
    person.dob && moment(person.dob).isValid()
      ? moment().diff(person.dob, "years", false)
      : person.age || "N/A";

  return (
    <>
      <LayoutHeader>
        <Breadcrumb title="Account" to={`/account/${account.id}`}>
          {account.name}
        </Breadcrumb>
        <Breadcrumb title="Person">
          {person.firstName} {person.lastName}
        </Breadcrumb>
        <Breadcrumb
          title="Account Balance"
          right
          to={`/account/${account.id}/transactions`}
        >
          <Format type="currency" value={account.accountBalance} color />
        </Breadcrumb>
        <Notes accountId={account.id} />
      </LayoutHeader>

      <div className="personContainer">
        <div className="personProfile">
          <ProfilePicture person={person} />
          <div className="personName">
            {person.firstName} {person.lastName}
          </div>
          <div className="personDetails" title="Gender | Age | Date of Birth">
            {person.gender || "N/A"}
            <span>|</span>
            {age}
            <span>|</span>
            {moment(person.dob).isValid()
              ? moment(person.dob).format("MMM D, YYYY")
              : "Unknown"}
          </div>

          {person.phone && (
            <div className="personEmail">
              <a href={`tel:${person.phone}`}>
                {person.phone && person.phone.length === 10
                  ? person.phone
                      .replace(/[^0-9]/g, "")
                      .match(/^(\d{3})(\d{3})(\d{4})$/)
                      .slice(1, 4)
                      .reduce((num, part) => (num += "-" + part))
                  : person.phone}
              </a>
            </div>
          )}

          {person.email && (
            <div className="personEmail">
              <a href={`mailto:${person.email}`}>{person.email}</a>
            </div>
          )}

          <div className="personAddress">
            {person.address} {person.address2}
            <br />
            {person.city}, {person.province} {person.country}
            <br />
            {person.postal}
          </div>

          <div className="personLink">{person.link}</div>
        </div>
        <div className="personMain">
          <h1>Registrations</h1>
          <PersonPrograms person={person} />
        </div>
      </div>

      {modal === "NEW_REGISTRATION" && (
        <Modal>
          <ModalClose />
          <ModalHeader>Register {person.firstName} for a Program</ModalHeader>
          <Register
            account={account}
            person={person}
            onComplete={() => setModal()}
          />
        </Modal>
      )}

      {/* <Register accountId={ accountId } person={ person } /> */}
      {/* <PersonForm accountId={ accountId } person={ person } /> */}
      <Button material onClick={() => setModal("NEW_REGISTRATION")}>
        <i className="material-icons">add</i>
      </Button>
    </>
  );
}

export function NewPerson({ accountId, personId }) {
  return (
    <>
      <h1>{personId ? "Edit Person" : "Add a Person"}</h1>
      <Edit accountId={accountId} personId={personId} />
    </>
  );
}

function PersonPrograms({ person }) {
  const { loading, error, data } = useQuery(GET_REGISTRATIONS_BY_PERSON_ID, {
    variables: { id: person.id },
  });

  if (loading) return "Loading...";
  if (error) return "Error";

  const { registrations } = data;

  if (registrations.length < 1)
    return (
      <NoData>
        {person.firstName} has not been registered for any programs.
      </NoData>
    );

  const now = moment().unix();
  const nowYear = moment().year();

  return registrations.map((registration, key) => {
    let tense = "future";
    if (moment(registration.programDate.startDate).unix() < now) tense = "past";
    if (
      moment(registration.programDate.startDate).unix() <= now &&
      moment(registration.programDate.endDate).unix() >= now
    )
      tense = "present";

    let yearTense = tense;
    if (
      yearTense === "future" &&
      moment(registration.programDate.startDate).year() === nowYear
    )
      yearTense = "present";

    const snackbarAmount = registration.snackbarAccount
      ? registration.snackbarAccount.reduce(
          (total, deposit) => (total += deposit.amount),
          0
        )
      : 0;
    const blockSnackbarBonus = registration.snackbarAccount
      ? registration.snackbarAccount.some(
          (deposit) => deposit.depositType === "Invitation Bonus"
        )
      : false;

    return (
      <div key={registration.id}>
        <Card indent>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr .5fr" }}>
            <CardDate tense={tense}>
              {registration.programDate.startDate}
            </CardDate>
            {/* <CardTitle title='Season'>
                            { registration.season.name }
                        </CardTitle>
                        <CardTitle title='Category'>
                            { registration.category.name }
                        </CardTitle> */}
            <CardTitle title="Program">
              {registration.program.name}{" "}
              {registration.programDate.customName &&
                `(${registration.programDate.customName})`}
            </CardTitle>

            <CardTitle title="Friend Request">
              <FriendRequest
                friendRequests={registration.friendRequests}
                registrationId={registration.id}
                personId={person.id}
                tense={tense}
              />
            </CardTitle>

            {snackbarAmount && (
              <CardTitle title="Snackbar">
                <Format value={snackbarAmount} type="currency" />
                {!blockSnackbarBonus && (
                  <SnackbarBonus
                    registrationId={registration.id}
                    personId={person.id}
                  />
                )}
              </CardTitle>
            )}
          </div>
        </Card>
        <div className={`timeline timeline--${tense}`} />
        {!registrations[key + 1] ||
        registration.programDate.startDate.substr(0, 4) !==
          registrations[key + 1].programDate.startDate.substr(0, 4) ? (
          <>
            <div className={`timelineYear timelineYear--${yearTense}`}>
              <div className={`year`}>
                {registration.programDate.startDate.substr(0, 4)}
              </div>
            </div>
            {registrations[key + 1] && (
              <div className={`timeline timeline--${yearTense}`} />
            )}
          </>
        ) : null}
      </div>
    );
  });
}

function FriendRequest({ friendRequests, registrationId, personId, tense }) {
  const { modal, setModal } = useModal();
  const [friendRequest] = useMutation(FRIEND_REQUEST, {
    refetchQueries: [
      { query: GET_REGISTRATIONS_BY_PERSON_ID, variables: { id: personId } },
    ],
  });

  if (friendRequests && friendRequests.length > 0)
    return friendRequests.map((request) => <span>{request.fullName}</span>);

  if (modal === `FRIEND_REQUEST_${registrationId}`)
    return (
      <Modal>
        <ModalClose />
        <ModalHeader>Friend Request</ModalHeader>
        <Form
          onSubmit={({ state: { inputs } }) => {
            friendRequest({
              variables: {
                payload: {
                  registrationId,
                  fullName: inputs.fullName,
                },
              },
            });
            setModal();
          }}
        >
          <Input name="fullName" label={`Friend's Name`} />
          <Submit>Add Friend Request</Submit>
        </Form>
      </Modal>
    );

  return tense === "future" ? (
    <button
      type="button"
      onClick={() => setModal(`FRIEND_REQUEST_${registrationId}`)}
    >
      New Friend Request
    </button>
  ) : (
    "N/A"
  );
}

function SnackbarBonus({ registrationId, personId }) {
  const { modal, setModal } = useModal();
  const [addSnackbarBonus] = useMutation(SNACKBAR_BONUS, {
    refetchQueries: [
      { query: GET_REGISTRATIONS_BY_PERSON_ID, variables: { id: personId } },
    ],
  });

  if (modal === `SNACKBAR_BONUS_${registrationId}`)
    return (
      <Modal>
        <ModalClose />
        <ModalHeader>Snackbar Bonus</ModalHeader>
        <Form
          onSubmit={({ state: { inputs } }) => {
            addSnackbarBonus({
              variables: {
                payload: {
                  registrationId,
                  invitedByCode: inputs.invitedByCode,
                },
              },
            });
            setModal();
          }}
        >
          <Input name="invitedByCode" label={`Friend's Invitation Code`} />
          <Submit>Add Snackbar Bonus</Submit>
        </Form>
      </Modal>
    );

  return (
    <button
      type="button"
      onClick={() => setModal(`SNACKBAR_BONUS_${registrationId}`)}
    >
      Add Snackbar Bonus
    </button>
  );
}
