import React from 'react'

export default function Fieldset({ legend, children }) {

    return (
        <fieldset
            // className='card'
        >
            <legend>{ legend }</legend>
                { children }
        </fieldset>
    )

}