import gql from "graphql-tag";

export const REMOVE_DEVICE = gql`
  mutation RemoveDevice($id: ID!) {
    removeDevice(id: $id)
  }
`;

export const LINK_DEVICE = gql`
  mutation LinkDevice($payload: LinkDevicePayload!) {
    linkDevice(payload: $payload) {
      uuid
      isActive
      meta {
        client {
          type
          name
          version
          engine
          engineVersion
        }
        os {
          name
          version
          platform
        }
        device {
          type
          brand
          model
        }
        bot
      }
    }
  }
`;

export const GET_LINKED_DEVICES = gql`
  query GetLinkedDevices {
    linkedDevices {
      uuid
      isActive
      nickname
      userType
      meta {
        client {
          type
          name
          version
          engine
          engineVersion
        }
        os {
          name
          version
          platform
        }
        device {
          type
          brand
          model
        }
        bot
      }
    }
  }
`;
