import React, { useState } from 'react'
import Form, { Input, Submit } from '../../components/Form'
import './register.scss'
import Card from '../../components/Card'
import { useQuery } from '@apollo/react-hooks'
import { useRegistration } from '../../hooks/registration'
import { SEARCH_FAMILIES } from '../../operations/Account'
import { useAccount } from '../../hooks/account'

export default function Register() {

    // const { registration } = useRegistration()
    const [term, setTerm] = useState('')
   
    // if (registration.person) {
    //     return (
    //         <div className='registerContainer'>
    //             <h1>Select a program</h1>
    //             <SelectProgram />
    //         </div>
    //     )
    // }

    return (
        <div className='registerContainer'>
            <h1>Register</h1>
            <Form onSubmit={({state: { inputs }}) => {
                setTerm(inputs.fullName)
            }}>
                <Input name='fullName' label='Search for a person...'/>
                <Submit>Search</Submit>
            </Form>
            {
                term && <Search term={term} />
            }
        </div>
    )
}

function Search({ term }) {

    const { setRegistration } = useRegistration()
    const { setAccount } = useAccount()

    const { loading, error, data } = useQuery(SEARCH_FAMILIES, {
        variables: {
            term
        }
    })

    if (loading) return 'Loading...'
    if (error) return 'Error'

    const keywords = term.split(' ')
    const terms = {}
    if (keywords.length === 2) {
        terms.firstName = keywords[0]
        terms.lastName = keywords[1]
    }
    else {
        terms.lastName = keywords[0]
    }

    return data.families.map(family => (
        <Card key={family.id} to={ `/account/${family.id}` } onClick={() => { setRegistration({ isOpen: false }); setAccount(family); } } >
            {family.name}
            {
                family.members.map((member, i) => (
                    <div key={i}>
                        <span style={{ fontWeight: terms.firstName && terms.firstName.toLowerCase() === member.firstName.toLowerCase() ? 'bold' : 'normal' }}>{ member.firstName }</span>
                        <span style={{ fontWeight: terms.lastName && terms.lastName.toLowerCase() === member.lastName.toLowerCase() ? 'bold' : 'normal' }}>{ member.lastName }</span></div>
                )) 
            }
        </Card>
    ))
}

// function SelectProgram() {
    
//     const { loading, error, data } = useQuery(GET_SEASONS)
//     const [season, setSeason] = useState()
//     const [category, setCategory] = useState()
//     const [program, setProgram] = useState()

//     if (loading) return 'Loading...'
//     if (error) return 'Error'

//     if (!season) return (
//         <>
//             {
//                 data.seasons.map(({ id, name }) => {
//                     return (
//                         <Card 
//                             key={id}
//                             onClick={() => setSeason(id)}
//                         >
//                             { name }
//                         </Card>
//                     )
//                 })
//             }
//         </>
//     )

//     if (!category) {

//         return data.seasons.find(({ id }) => season === id).categories.map(({ id, name }) => {
//             return (
//                 <Card 
//                     key={ id }
//                     onClick={() => setCategory(id)}
//                 >
//                     { name }
//                 </Card>
//             )
//         })
//     }

//     if (!program) {

//         return data.seasons.find(({ id }) => season === id).categories.find(({ id }) => category === id).programs.map(({ id, name }) => {
//             return (
//                 <Card 
//                     key={ id }
//                     onClick={() => setProgram(id)}
//                 >
//                     { name }
//                 </Card>
//             )
//         })

//     }

//     const { id } = data.seasons.find(({ id }) => season === id).categories.find(({ id }) => category === id).programs.find(({ id }) => program === id)

//     return <ProgramDates id={ id } />

// }

// function ProgramDates({ id }) {

//     const { loading, error, data } = useQuery(GET_PROGRAM_DATES, { 
//         variables: { 
//             filter: { 
//                 programId: { eq: id },
//                 endDate: { gte: moment().format('YYYY-MM-DD') }
//             } 
//         } 
//     })

//     if (loading) return 'Loading...'
//     if (error) return `Error: ${error.message}`

//     return data.programDates.map(date => {
//         return (
//             <Card
//                 key={ date.id }
//                 onClick={() => alert(date.id)}
//             >
//                 <Grid style={{ gridTemplateColumns: 'auto auto auto auto' }}>
//                     <CardTitle title='Start Date'>
//                         { moment(date.startDate).format('dddd, MMMM D, YYYY') }
//                     </CardTitle>
//                     <CardTitle title='End Date'>
//                         { moment(date.endDate).format('dddd, MMMM D, YYYY') }
//                     </CardTitle>
//                     <CardTitle title='Age'>
//                         { date.ageMin } - { date.ageMax }
//                     </CardTitle>
//                     <CardTitle title='Capacity'>
//                         <Capacity capacity={date.capacity} currentCapacity={date.currentCapacity} />
//                     </CardTitle>
//                 </Grid>
//             </Card>
//         )
//     })

// }