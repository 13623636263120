import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "../Button";
import { useModal } from "../../hooks/modal";
import "./modal.css";

const modalRoot = document.getElementById("modal-root");

export default function Modal({ children, print }) {
  const [mounted, setMounted] = useState(false);

  const el = document.createElement("div");
  el.setAttribute(
    "class",
    print ? "modalContainer modalContainer--print" : "modalContainer"
  );

  useEffect(() => {
    modalRoot.appendChild(el);
    setMounted(true);

    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  if (!mounted) return null;

  return ReactDOM.createPortal(
    print ? (
      <>{children}</>
    ) : (
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        {children}{" "}
      </div>
    ),
    el
  );
}

export function ModalHeader({ children }) {
  return <div className="modalHeader">{children}</div>;
}

export function ModalTitle({ title, children }) {
  return (
    <div className="modalTitleContainer">
      <div className="modalTitle">{title}</div>
      {children}
    </div>
  );
}

export function ModalClose() {
  const { setModal } = useModal();

  return (
    <Button
      className="modalClose"
      unstyled
      onClick={(e) => {
        e.stopPropagation();
        setModal();
      }}
    >
      <i className="material-icons">close</i>
    </Button>
  );
}
