import { useQuery } from "@apollo/react-hooks";
import Card, { CardTitle } from "components/Card";
import Form, { Search } from "components/Form";
import Format from "components/Format";
import Grid from "components/Grid";
import { LayoutHeader } from "components/Layout";
import NoData from "components/NoData";
// import currency from "currency.js";
import { GET_RECENT_TRANSACTIONS } from "operations/Transaction";

import React, { useState } from "react";
export default function Transactions() {
  const [searchTerm, setSearchTerm] = useState("");
  const { error, loading, data } = useQuery(GET_RECENT_TRANSACTIONS, {
    variables: {
      ...(searchTerm && {
        filter: {
          transactionId: {
            eq: String((Number(searchTerm) || 10000) - 10000),
          },
        },
      }),
    },
  });
  if (loading) return "Loading transactions";
  if (error) return "Error loading transactions";

  const transactions = data.recentTransactions;

  // const transactions = searchTerm
  //   ? data.recentTransactions.filter((current) =>
  //       current.id.includes(Number(searchTerm) - 10000)
  //     )
  //   : data.recentTransactions;

  return (
    <>
      <LayoutHeader>
        <div style={{ marginLeft: "2rem", maxWidth: "320px" }}>
          <Form
            onSubmit={({ state: { inputs }, enableSubmit }) => {
              setSearchTerm(inputs.search);
              enableSubmit();
            }}
          >
            <Search label="Search by invoice number" />
          </Form>
        </div>
      </LayoutHeader>
      <h1 style={{ marginTop: "2rem" }}>Transactions</h1>
      <div>
        {transactions && transactions.length < 1 && (
          <NoData>No transactions to display</NoData>
        )}
        {transactions &&
          transactions.map((transaction, i) => {
            return (
              <Card
                key={i}
                to={`/account/${transaction.account.id}/transactions?transactionId=${transaction.id}`}
              >
                <Grid
                  style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                >
                  <CardTitle title="Invoice #">
                    {Number(transaction.id) + 10000}
                  </CardTitle>
                  <CardTitle title="Date">
                    <Format
                      type="short_date"
                      value={transaction.transactionDate}
                    />
                  </CardTitle>
                  <CardTitle title="Charged" right>
                    <Format type="currency" value={transaction.total} />
                  </CardTitle>
                  {/* <CardTitle title="Credited" right>
                    <Format
                      type="currency"
                      value={currency(transaction.creditUsed).add(
                        transaction.payments.reduce(
                          (total, payment) =>
                            currency(total).add(
                              payment.paymentType === "Account Credit"
                                ? payment.amount
                                : 0
                            ).value,
                          0
                        )
                      )}
                    />
                  </CardTitle>
                  <CardTitle title="Paid" right>
                    <Format
                      type="currency"
                      value={transaction.payments.reduce(
                        (total, payment) =>
                          currency(total).add(
                            payment.paymentType !== "Account Credit"
                              ? payment.amount
                              : 0
                          ).value,
                        0
                      )}
                    />
                  </CardTitle>
                  <CardTitle title="Amount Due" right>
                    <Format
                      type="currency"
                      value={
                        currency(transaction.total)
                          .subtract(transaction.creditUsed || 0)
                          .subtract(
                            transaction.payments.reduce(
                              (total, payment) =>
                                currency(total).add(payment.amount).value,
                              0
                            )
                          ).value
                      }
                      colorInvert
                    />
                  </CardTitle> */}
                </Grid>
                {/* {JSON.stringify(transaction)} */}
              </Card>
            );
          })}
      </div>
    </>
  );
}
