import React from "react";
import { useForm } from "./Form";

export default function Submit({ children, disabled }) {
  const [{ submitted }] = useForm();

  return (
    <button type="submit" disabled={submitted || disabled} className="submit">
      {children}
    </button>
  );
}

export function Submit2({ children, disabled, className = "" }) {
  const [{ submitted }] = useForm();

  return (
    <button
      type="submit"
      disabled={submitted || disabled}
      className={className}
    >
      {children}
    </button>
  );
}
