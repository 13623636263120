import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_BALANCE_DECISIONS } from 'operations/Transaction'
import Card, { CardTitle } from '../../components/Card'
import Format from 'components/Format'

const DECISIONS = {
    GIFT: 'Gift',
    CREDIT: 'Credit',
    PARTIAL_REFUND_GIFT: 'Partial Refund w. Gift',
    PARTIAL_REFUND_CREDIT: 'Partial Refund w. Credit',
    PARTIAL_CREDIT_GIFT: 'Partial Credit w. Gift',
    FULL_REFUND: 'Full Refund'
}

export default function Refunds() {

    const { error, loading, data } = useQuery(GET_BALANCE_DECISIONS)

    if (error || loading) return 'Loading...'

    const totals = {
        refund: 0,
        credit: 0,
        gift: 0
    }

    data.balanceDecisions.map(transaction => {

        if (['GIFT', 'PARTIAL_REFUND_GIFT'].includes(transaction.balanceDecision)) {
            totals.gift += transaction.remainderAmount
            return totals.refund += transaction.refundAmount
        }
        if (['CREDIT', 'PARTIAL_REFUND_CREDIT'].includes(transaction.balanceDecision)) {
            totals.credit += transaction.remainderAmount
            return totals.refund += transaction.refundAmount
        }
        if (['PARTIAL_CREDIT_GIFT'].includes(transaction.balanceDecision)) {
            totals.credit += transaction.refundAmount
            return totals.gift += transaction.remainderAmount
        }
            
        if (['FULL_REFUND'].includes(transaction.balanceDecision)) return totals.refund += transaction.refundAmount

        return null

    })

    return (
        <>
            <h1>Refunds</h1>
        
            <div>Total gifted amount: <Format type='currency' value={ totals.gift } /></div>
            <div>Total credit amount: <Format type='currency' value={ totals.credit } /></div>
            <div>Total refund amount: <Format type='currency' value={ totals.refund } /></div>
            <div>Total responses: { data.balanceDecisions.length }</div>

            {
                data.balanceDecisions.map(transaction => {
                    return (
                        <Card key={ transaction.id } to={`/account/${ transaction.account.id }`}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '200px 300px 1fr 1fr'
                            }}
                        >
                            <CardTitle title='Invoice #'>
                                { Number(transaction.id) + 10000 }
                            </CardTitle>
                            <CardTitle title='Decision'>
                                { DECISIONS[transaction.balanceDecision] }
                            </CardTitle>
                            <CardTitle title='Refund'>
                                <Format type='currency' value={ transaction.refundAmount } />
                            </CardTitle>
                            <CardTitle title={ transaction.balanceDecision.includes('CREDIT') ? 'Credit' : transaction.balanceDecision.includes('GIFT') ? 'Gift' : 'Remainder' }>
                                <Format type='currency' value={ transaction.remainderAmount } />
                            </CardTitle>
                        </Card>
                    )
                })
            }

        </>
    )
}