import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { REFRESH_TOKEN } from '../operations/Account'
import { navigate } from '@reach/router'

const AuthenticationContext = createContext()

function AuthenticationProvider({ init = false, children }) {

    const client = useApolloClient()
    const [isAuthenticated, setAuthenticated] = useState(init)
    const [user, setUser] = useState({})
    const [tokenExpires, setTokenExpiry] = useState()
    // const { resetGlobalState } = useGlobalState()

    const logout = useCallback(
        () => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            setTokenExpiry()
            setAuthenticated(false)
            setUser({})
            // resetGlobalState()
            client.clearStore()
            navigate('/')
        },
        [client]
    )

    const [ refresh ] = useMutation(REFRESH_TOKEN)

    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken') || false
        if (accessToken) {
            const decoded = jwtDecode(accessToken)
            const { knd, exp } = decoded
            if (knd === 'ACCESS' && exp > moment().unix()) {
                setTokenExpiry(exp)
                setUser(decoded)
                setAuthenticated(true)
            }
            else {
                logout()
            }
        }

    }, [isAuthenticated, logout])

    useEffect(() => {
        const refreshTimer = setInterval(() => {
            if (tokenExpires < moment().add(5, 'minutes').unix()) {

                const refreshToken = localStorage.getItem('refreshToken') || null

                if (refreshToken) {

                    refresh({
                        variables: {
                            payload: {
                                refreshToken
                            }
                        }
                    })
                    .then(({data}) => {
                        const { accessToken } = data.refreshToken
                        const { exp } = jwtDecode(accessToken)
                        localStorage.setItem('accessToken', accessToken)
                        setTokenExpiry(exp)
                    })
                    .catch(err => {
                        logout()
                    })

                }

                else {
                    logout()
                }
                
            }
        }, 60000)

        return () => {
            clearInterval(refreshTimer)
        }

    }, [tokenExpires, refresh, logout])

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                user,
                setAuthenticated: (arg) => setAuthenticated(arg),
                logout: () => logout()
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    )        

}

function useAuthentication() {
    return useContext(AuthenticationContext)
}

export {
    useAuthentication
}

export default AuthenticationProvider