import React, { useState } from 'react'
import fileDownload from 'js-file-download'
import Papa from 'papaparse'
import moment from 'moment'

import { conditions } from '../../functions/conditions'

export default function Settings() {

    const [selectedConditions, setSelectedConditions] = useState([[]])

    function selectCondition({ key, value, level }) {

        // console.log('INPUT', key, value, level)
        
        setSelectedConditions(state => {

            const newState = state

            if (typeof level !== 'undefined') {

                if (state[key][level]) {

                    newState[key][level] = value

                    return [
                        ...newState
                    ]
                }

                newState[key].push(value)

                return [
                    ...newState
                ]
            }      
            
            if (state[key]) {
                newState[key] = [value]
                return [
                    ...newState
                ]
            }
            
            return [
                ...newState,
                [ value ]
            ]
            
        })
    }

    // console.log(selectedConditions)

    // if (selectedConditions.length < 1) {
    //     return (
    //         <>
    //             <select onChange={ ({ target: { value } }) => selectCondition({ value }) }>
    //                 <option value=''></option>
    //                 {
    //                     conditions.map(condition => <option key={ condition.name } value={ condition.name }>{ condition.title }</option>)
    //                 }
    //             </select>
    //             <button type='button' onClick={() => setSelectedConditions(state => [...state, []])}>Add</button>
    //         </>
    //     )
    // }

    function download() {

        const data = [
            ['!ACCNT', 'NAME', 'ACCNTTYPE', 'SCD'],
            ['ACCNT', 'Undeposited Funds', 'OCASSET'],
            ['ACCNT', 'Office Registrations', 'INC'],
            ['ACCNT', 'Snack Bar Deposits', 'INC'],
            ['ACCNT', 'Sales Tax Payable', 'OCLIAB'],
            ['!TRNS', 'TRNSID', 'TRNSTYPE', 'DATE', 'ACCNT', 'AMOUNT', 'MEMO', 'NAME'],
            ['!SPL', 'SPLID', 'TRNSTYPE', 'DATE', 'ACCNT', 'AMOUNT', 'MEMO', 'NAME'],
            ['!ENDTRNS'],
        ]

        const transactions = [
            {
                type: 'CASH SALE',
                date: '04/26/2019',
                amount: 1050.90,
                memo: 'Registration - Adventure Camp',
                snackbar: 50,
                name: '',
                sale: 'dane.stevens@live.com'
            }
        ]

        transactions.map(transaction => {
            data.push(['TRNS', '', transaction.type, transaction.date, 'Undeposited Funds', transaction.amount + transaction.snackbar, '', transaction.sale])
            data.push(['SPL', '', transaction.type, transaction.date, 'Office Registrations', -Math.abs(transaction.amount / 1.13), transaction.memo, transaction.sale])
            data.push(['SPL', '', transaction.type, transaction.date, 'Snack Bar Deposits', -Math.abs(transaction.snackbar), transaction.memo, transaction.sale])
            data.push(['SPL', '', transaction.type, transaction.date, 'Sales Tax Payable', -Math.abs(transaction.amount / 1.13 * .13), 'HST @ 13 %', transaction.sale])
            data.push(['ENDTRNS'])
            return null
        })

        const iif = Papa.unparse(data, { delimiter: '\t' })

        fileDownload(iif, `encampmentTransactionImport_${ moment().format('YYMMDD-HHmmss') }.iif`)

    }

    return (
        <>
            {
                selectedConditions.map((selectedCondition, primaryKey) => {

                    const selectedConditionData = conditions.find(condition => condition.name === selectedCondition[0])
                    let fieldData = selectedConditionData ? selectedConditionData.fields : []
            
                    return (
                        <div key={ primaryKey }>
                            <select onChange={ ({ target: { value } }) => selectCondition({ key: primaryKey, value }) } value={ selectedCondition[0] }>
                                <option value=''></option>
                                { 
                                    conditions.map(condition => <option key={ condition.name } value={ condition.name }>{ condition.title }</option>)
                                }
                            </select>
                            {
                                
                                selectedCondition[0] && selectedCondition.map((conditionData, key) => {
                                    // console.log('CONDITIONDATA', conditionData, fieldData)
            
                                    if (key > 0) {
                                        const newFieldData = fieldData.find(data => data.name === conditionData)
                                        if (!newFieldData || !newFieldData.fields) return null
                                        fieldData = newFieldData.fields
                                    }
            
                                    if (!fieldData) return null
            
                                    return (
                                        <select key={ key } onChange={ ({ target: { value } }) => selectCondition({ key: primaryKey, value, level: key + 1 }) }>
                                            <option value=''></option>
                                            {
                                                fieldData.map(condition => {
                                                    return <option key={ condition.name } value={ condition.name }>{ condition.title }</option>
                                                })
                                            }
                                        </select>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            <button type='button' onClick={() => setSelectedConditions(state => [...state, []])}>Add</button>

            <button type='button' onClick={() => download()}>Download</button>
        </>
    )

}

