import React, { useState } from "react";
import { WEEKS } from "pages/Credits";
import "../Credits/credits.scss";
import { DateTime } from "luxon";

const downloadLink =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://api.teenranch.com";

export default function Exports() {
  const [week, setWeek] = useState(0);

  const token = localStorage.getItem("accessToken");
  const weekData = WEEKS[week];
  return (
    <div>
      <div className="weekContainer">
        {WEEKS.map((weekData, i) => (
          <button
            key={i}
            type="button"
            className={`weekButton ${week === i ? "selected" : ""}`}
            onClick={() => setWeek(i)}
          >
            {DateTime.fromISO(weekData.startDate).toLocaleString(
              DateTime.DATE_FULL
            )}
          </button>
        ))}
      </div>

      <h1>Exports</h1>

      <div className="tw-flex tw-gap-4">
        <a
          href={`${downloadLink}/exports/medical/medicalExport_${weekData.startDate}_${weekData.endDate}?t=${token}&startDate=${weekData.startDate}&endDate=${weekData.endDate}`}
          download
          className="tw-bg-blue-600 tw-py-2 tw-px-4 tw-rounded tw-inline-block hover:tw-bg-blue-800 tw-text-white"
        >
          Export Medical
        </a>
        <a
          href={`${downloadLink}/exports/camperList/camperList_${weekData.startDate}_${weekData.endDate}?t=${token}&startDate=${weekData.startDate}&endDate=${weekData.endDate}`}
          download
          className="tw-bg-blue-600 tw-py-2 tw-px-4 tw-rounded tw-inline-block hover:tw-bg-blue-800 tw-text-white"
        >
          Export Camper List
        </a>
      </div>
    </div>
  );
}
