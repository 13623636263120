import React, { createContext, useContext, useState } from 'react'

const RegistrationContext = createContext()

export default function Registration({ children, init = { isOpen: false } }) {

    const [state, setState] = useState(init)

    

    return (
        <RegistrationContext.Provider
            value={{
                registration: state,
                setRegistration: (newState) => setState(state => ({ ...state, ...newState }))
            }}
        >
            { children }
        </RegistrationContext.Provider>
    )

}

export const useRegistration = () => useContext(RegistrationContext)