import React, { useState, useEffect, useRef } from 'react'
import { useForm } from './Form'
import InputContainer from './InputContainer'
// import ShortcutKeys from '../ShortcutKeys'

export default function Search({ label, name='search', value = '', type = 'text', onChange, placeholder }) {

    const [isFocused, setFocused] = useState(false)

    const [{ inputs }, setInput] = useForm()

    // Init the input
    useEffect(() => {
        if (typeof inputs[name] === 'undefined') {
            setInput({[name]: value})
        }
    }, [inputs, name, setInput, value])

    const inputId = `f_${name}`

    const ref = useRef(null)

    return (
        <InputContainer type='search' label={ label } inputId={ inputId } isFocused={ isFocused } isFilled={ inputs[name] }>
            {/* <ShortcutKeys keyPressed={(key) => {
                if (key === 's') {
                    ref.current.focus()
                }
            }}/> */}
            <input
                ref={ ref }
                id={ inputId }
                type='search'
                name={ name }
                value={ inputs[name] || '' }
                onChange={e => {
                    setInput({
                        [name]: e.target.value
                    })
                    if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
                }}
                onFocus={ () => setFocused(true) }
                onBlur={ () => setFocused(false) }
            />
        </InputContainer>
    )

}