import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_ACCOUNT_GUARDIANS } from 'operations/Account'
import Card from 'components/Card'

export default function AuthorizedPickUp({ accountId }) {

    const { error, loading, data } = useQuery(GET_ACCOUNT_GUARDIANS, { variables: { accountId } })

    console.log(data)

    if (error) return 'Error loading Authorized Pickup'
    if (loading) return 'Loading...'

    return (
        <>
            {
                data.guardians.guardians.map((guardian) => <Card key={ guardian.id }>{ guardian.firstName } { guardian.lastName }</Card>)
            }
            {
                data.guardians.authorizedPickup.map((guardian) => <Card key={ guardian.id }>{ guardian.firstName } { guardian.lastName }</Card>)
            }
        </>
    )
}