import gql from 'graphql-tag'

export const GET_PAGINATED_EMAILS = gql`
    query Emails($paginate: Paginate! $term: String) {
        emails(paginate: $paginate term: $term) {
            edges {
                node {
                    id
                    uuid
                    rcptTo
                    friendlyFrom
                    msgFrom
                    subject
                    text
                    html
                    headers
                    isRead
                    createdAt
                }
            }
            cursors {
                before
                after
                hasNext
                hasPrevious
            }
        }
    }
`

export const GET_EMAIL = gql`
    query Email($uuid: ID!) {
        email(uuid: $uuid) {
            id
            uuid
            to
            rcptTo
            friendlyFrom
            msgFrom
            subject
            text
            html
            headers
            isRead
            createdAt
        }
    }
`

export const SET_EMAIL_READ = gql`
    mutation SetEmailRead($uuid: ID!) {
        setEmailRead(uuid: $uuid) {
            id
            isRead
        }
    }
`