import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./Apollo";

import RefreshProvider from "./hooks/refresh";
import AuthenticationProvider from "./hooks/authentication";
import NotificationProvider from "./hooks/notification";
import DateRangeProvider from "./hooks/dateRange";
import AccountProvider from "./hooks/account";
import RegistrationProvider from "./hooks/registration";
import ModalProvider from "./hooks/modal";

// import print, { printSetup } from './components/Print'
import "./styles/default.scss";
import "./index.css";

Sentry.init({
  dsn: "https://0c95cd3950d944c0980c5dd83766338c@sentry.io/1783384",
  environment: process.env.NODE_ENV,
});

// printSetup()

ReactDOM.render(
  <RefreshProvider>
    {/* <button type='button' onClick={() => print()}>Print</button> */}
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <ModalProvider>
          <NotificationProvider>
            <DateRangeProvider>
              <AccountProvider>
                <RegistrationProvider>
                  <Routes />
                </RegistrationProvider>
              </AccountProvider>
            </DateRangeProvider>
          </NotificationProvider>
        </ModalProvider>
      </AuthenticationProvider>
    </ApolloProvider>
  </RefreshProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
