import React, { useState, useEffect } from "react";
// import { LayoutHeader } from "../../components/Layout";
// import Breadcrumb from "../../components/Breadcrumb";
import Scroll from "../../components/Scroll";
// import { useDateRange } from "../../hooks/dateRange";
// import Calendar from "react-calendar";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  GENERATE_CAMPER_LIST,
  GET_CABIN_LEADERS,
  GET_REGISTRATION_ACCOMMODATIONS,
  SAVE_CABIN_LEADERS,
  SAVE_REGISTRATION_ACCOMMODATION,
  UPDATE_FRIEND_REQUEST,
} from "../../operations/Program";
import Card, { CardProfile, CardTitle } from "components/Card";
import Grid from "components/Grid";
import Form, {
  FormContext,
  Input,
  Select,
  Submit,
  Submit2,
} from "components/Form";
import NoData from "components/NoData";
import { DateTime } from "luxon";
import { createId } from "@paralleldrive/cuid2";
import { Icon } from "@iconify/react";

const downloadLink =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://api.teenranch.com";

const WEEKS = [
  // {
  //   startDate: "2023-07-03",
  //   endDate: "2023-07-07",
  // },
  {
    startDate: "2024-07-07",
    endDate: "2024-07-12",
  },
  {
    startDate: "2024-07-14",
    endDate: "2024-07-19",
  },
  {
    startDate: "2024-07-21",
    endDate: "2024-07-26",
  },
  {
    startDate: "2024-07-28",
    endDate: "2024-08-02",
  },
  {
    startDate: "2024-08-05",
    endDate: "2024-08-09",
  },
  {
    startDate: "2024-08-11",
    endDate: "2024-08-16",
  },
  // {
  //   startDate: "2023-08-28",
  //   endDate: "2023-09-01",
  // },
];

const rooms = [
  {
    id: "Carriage House_1",
    camperBeds: 7,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_2",
    camperBeds: 5,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_3",
    camperBeds: 7,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_4",
    camperBeds: 7,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_5",
    camperBeds: 5,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_6",
    camperBeds: 5,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_9",
    camperBeds: 5,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Carriage House_10",
    camperBeds: 5,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Sittler",
    camperBeds: 4,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Gartner",
    camperBeds: 5,
    leaderBeds: 0,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Ellis",
    camperBeds: 4,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Boschman",
    camperBeds: 5,
    leaderBeds: 0,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Murphy",
    camperBeds: 9,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Henderson",
    camperBeds: 9,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Dunn",
    camperBeds: 9,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
  {
    id: "Coach House_Baylis",
    camperBeds: 9,
    leaderBeds: 1,
    bedsAssigned: 0,
    campers: [],
    isUnderTen: false,
  },
];

const roomOptions = [];
rooms.map((room) =>
  roomOptions.push([room.id, room.id.split("_").join(" - ")])
);

export default function Accommodations() {
  const [week, setWeek] = useState(0);
  const [cacheKey, setCacheKey] = useState(createId());
  const [editFriendRequest, setEditFriendRequest] = useState();
  const [showCabinLeaders, setShowCabinLeaders] = useState(false);

  const WEEK = WEEKS[week];

  const { loading, error, data } = useQuery(GET_REGISTRATION_ACCOMMODATIONS, {
    variables: {
      startDate: WEEK.startDate,
      endDate: WEEK.endDate,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingCabinLeaders,
    // error: errorCabinLeaders,
    data: dataCabinLeaders,
  } = useQuery(GET_CABIN_LEADERS, {
    variables: { startDate: WEEK.startDate, endDate: WEEK.endDate },
  });

  const [generateCamperList, { loading: loadingGenerate, data: dataGenerate }] =
    useMutation(GENERATE_CAMPER_LIST, {
      refetchQueries: [
        {
          query: GET_REGISTRATION_ACCOMMODATIONS,
          variables: { startDate: WEEK.startDate, endDate: WEEK.endDate },
        },
      ],
      fetchPolicy: "no-cache",
    });

  const [saveRegistrationAccommodation] = useMutation(
    SAVE_REGISTRATION_ACCOMMODATION,
    {
      refetchQueries: [
        {
          query: GET_REGISTRATION_ACCOMMODATIONS,
          variables: { startDate: WEEK.startDate, endDate: WEEK.endDate },
        },
      ],
      fetchPolicy: "no-cache",
    }
  );

  const [updateFriendRequest] = useMutation(UPDATE_FRIEND_REQUEST, {
    refetchQueries: [
      {
        query: GET_REGISTRATION_ACCOMMODATIONS,
        variables: { startDate: WEEK.startDate, endDate: WEEK.endDate },
      },
    ],
    fetchPolicy: "no-cache",
  });

  const [saveCabinLeaders] = useMutation(SAVE_CABIN_LEADERS);

  useEffect(() => {
    if (dataGenerate) setCacheKey(createId());
  }, [dataGenerate]);

  const token = localStorage.getItem("accessToken");

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const assigned =
    data &&
    data.registrationAccommodations &&
    data.registrationAccommodations.reduce(
      (total, current) => (total += current.reservationBuilding ? 1 : 0),
      0
    );

  const assignedTotal =
    data &&
    data.registrationAccommodations &&
    data.registrationAccommodations.length;

  const carriageHouse = rooms.filter((room) =>
    room.id.includes("Carriage House")
  );
  const coachHouse = rooms.filter((room) => room.id.includes("Coach House"));

  return (
    <>
      {/* <LayoutHeader>
        <Breadcrumb
          title="Date Range"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div onClick={() => setCalendarOpen(!calendarOpen)}>
            {startDate.format("MMM D, YYYY")} - {endDate.format("MMM D, YYYY")}
          </div>
          {calendarOpen && (
            <div style={{ position: "absolute", background: "#fff" }}>
              <Calendar
                selectRange
                calendarType="US"
                value={[new Date(startDate), new Date(endDate)]}
                onChange={([startDate, endDate]) => {
                  setStartDate(moment(startDate));
                  setEndDate(moment(endDate));
                  setCalendarOpen(false);
                }}
              />
            </div>
          )}
        </Breadcrumb>
      </LayoutHeader> */}
      <Scroll>
        <h1
          style={{
            display: "flex",
            gap: "2rem",
            justifyContent: "space-between",
          }}
        >
          Accommodations
          <div className="tw-flex tw-gap-8 tw-items-baseline">
            <div>
              <button
                type="button"
                className="tw-rounded tw-bg-blue-600 tw-text-white/90 tw-px-4 tw-py-2 hover:tw-bg-blue-800"
                onClick={() =>
                  generateCamperList({
                    variables: {
                      startDate: WEEK.startDate,
                      endDate: WEEK.endDate,
                    },
                  })
                }
              >
                {loadingGenerate
                  ? "Generating..."
                  : "Generate Room Assignments"}
              </button>
            </div>
            <div>
              <a
                href={`${downloadLink}/exports/cabinList/cabinList_${WEEK.startDate}_${WEEK.endDate}?t=${token}&startDate=${WEEK.startDate}&endDate=${WEEK.endDate}`}
                download
                className="tw-bg-blue-600 tw-py-2 tw-px-4 tw-rounded tw-inline-block hover:tw-bg-blue-800 tw-text-white"
              >
                Export Cabin List
              </a>
            </div>
            <div style={{ fontSize: "2rem" }}>
              Assigned: {assigned}/{assignedTotal}
            </div>
          </div>
        </h1>
        <div className="weekContainer">
          {WEEKS.map((weekData, i) => (
            <button
              key={i}
              type="button"
              className={`weekButton ${week === i ? "selected" : ""}`}
              onClick={() => setWeek(i)}
            >
              {DateTime.fromISO(weekData.startDate).toLocaleString(
                DateTime.DATE_FULL
              )}
            </button>
          ))}
        </div>

        {(!data.registrationAccommodations ||
          data.registrationAccommodations.length < 1) && (
          <NoData>
            No registrations to display. Select a different date range
          </NoData>
        )}

        <div>
          <button
            type="button"
            className="tw-rounded tw-px-4 tw-py-2 tw-bg-slate-200 tw-mt-8 hover:tw-bg-slate-300"
            onClick={() => setShowCabinLeaders(!showCabinLeaders)}
          >
            {showCabinLeaders ? "Hide" : "Show"} Cabin Leaders
          </button>
          {showCabinLeaders && (
            <div>
              {loadingCabinLeaders ? (
                "Loading cabin leaders..."
              ) : (
                <Form
                  init={
                    (dataCabinLeaders && dataCabinLeaders.getCabinLeaders) || {}
                  }
                  onSubmit={async ({ state: { inputs }, enableSubmit }) => {
                    console.log(inputs);
                    await saveCabinLeaders({
                      variables: {
                        startDate: WEEK.startDate,
                        endDate: WEEK.endDate,
                        cabinLeaders: inputs,
                      },
                    });
                    enableSubmit();
                  }}
                >
                  <div className="tw-grid tw-grid-cols-2 tw-gap-8">
                    <div>
                      {carriageHouse.map((room, i) => {
                        return (
                          <div key={i}>
                            <Input
                              name={`${room.id}`}
                              label={`Cabin Leader: ${room.id
                                .split("_")
                                .join(" - ")}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {coachHouse.map((room, i) => {
                        return (
                          <div key={i}>
                            <Input
                              name={`${room.id}`}
                              label={`Cabin Leader: ${room.id
                                .split("_")
                                .join(" - ")}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Submit>Save cabin leaders</Submit>
                </Form>
              )}
            </div>
          )}
        </div>

        {data &&
          data.registrationAccommodations &&
          data.registrationAccommodations.map((registration, i) => {
            const selected = [
              registration.reservationBuilding,
              registration.reservationRoom,
            ].join("_");

            const friendRequests = registration.friendRequests;

            const friendRequestName =
              (friendRequests &&
                friendRequests.length > 0 &&
                friendRequests[0].fullName) ||
              "";

            return (
              <div key={i}>
                <Card
                  key={i}
                  indent
                  style={{
                    background: "#fff",
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  <CardProfile person={registration.person} />
                  <Grid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}>
                    <CardTitle title="Name">
                      {registration.person.firstName}{" "}
                      {registration.person.lastName}
                    </CardTitle>
                    <CardTitle title="Gender">
                      {registration.person.gender}
                    </CardTitle>
                    <CardTitle title="Age">
                      {registration.person.dob &&
                      moment(registration.person.dob).isValid()
                        ? moment().diff(registration.person.dob, "years", false)
                        : registration.person.age || "N/A"}
                    </CardTitle>
                    <CardTitle title="Room assignment">
                      {registration.reservationBuilding}
                      {" - "}
                      {registration.reservationRoom}
                    </CardTitle>
                    <div style={{ margin: "-2.5rem 0" }}>
                      <Form key={cacheKey}>
                        <FormContext.Consumer>
                          {() => {
                            return (
                              <Select
                                name="room"
                                label="Assign a room"
                                options={roomOptions}
                                value={selected}
                                onChange={(e) => {
                                  const [building, room] =
                                    e.target.value.split("_");
                                  console.log(building, room);
                                  saveRegistrationAccommodation({
                                    variables: {
                                      registrationId: registration.id,
                                      building,
                                      room,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        </FormContext.Consumer>
                      </Form>
                    </div>
                  </Grid>
                </Card>
                <div
                  style={{
                    background: "#eee",
                    borderRadius: "0 0 4px 4px",
                    marginTop: "-24px",
                    position: "relative",
                    zIndex: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      padding: "14px 2rem 1rem 2rem",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "10pt" }}>Friend requests:</div>
                    {editFriendRequest === registration.id && (
                      <Form
                        onSubmit={async ({
                          state: { inputs },
                          enableSubmit,
                        }) => {
                          await updateFriendRequest({
                            variables: {
                              registrationId: registration.id,
                              friendName: inputs.friendRequest,
                            },
                          });
                          enableSubmit();
                          setEditFriendRequest();
                        }}
                      >
                        <div className="tw-flex tw-gap-2 tw-items-center">
                          <div className="tw-w-[500px]">
                            <Input
                              name="friendRequest"
                              label="Friend Request"
                              value={friendRequestName}
                              // value={
                              //   (friendRequest && friendRequest.fullName) || ""
                              // }
                            />
                          </div>
                          <Submit2 className="tw-py-2 tw-bg-blue-600 hover:tw-bg-blue-800 tw-px-8 tw-rounded tw-h-[54px] tw-text-white/90">
                            Save Friend Request
                          </Submit2>
                        </div>
                      </Form>
                    )}
                    {friendRequestName.split(",").map((friendRequest) => {
                      const friendRequestName = friendRequest.trim();
                      if (!friendRequestName) return null;
                      return (
                        <div
                          key={i}
                          style={{
                            background: "#333",
                            padding: ".5rem 1rem",
                            borderRadius: "1000px",
                            color: "#fff",
                          }}
                          className="tw-flex tw-items-center tw-gap-2"
                        >
                          {friendRequest}{" "}
                        </div>
                      );
                    })}
                    {editFriendRequest !== registration.id && (
                      <button
                        type="button"
                        className="hover:tw-text-blue-500 tw-cursor-pointer tw-flex tw-gap-2 tw-rounded-full tw-bg-slate-600 tw-px-4 tw-py-2 tw-items-center tw-text-white/90"
                        onClick={() => setEditFriendRequest(registration.id)}
                      >
                        Edit <Icon icon="mdi:edit" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </Scroll>
    </>
  );
}
