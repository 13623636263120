import React, { useState } from 'react'
import Form, { Input, Submit } from '../../components/Form'
import { useMutation } from '@apollo/react-hooks'
import { AUTHENTICATE } from '../../operations/Account'
import { useAuthentication } from '../../hooks/authentication'
import Error from '../../components/Error'
import Icon from '../../components/Icon'

import './login.scss'

const ERROR_MESSAGES = {
    'USER_NOT_FOUND': 'Sorry, but we were unable to find an account with that username.',
    'BAD_PASSWORD_MATCH': 'Sorry, but your password is incorrect.'
}

export default function Login () {

    const { setAuthenticated } = useAuthentication()
    const [ authenticate ] = useMutation(AUTHENTICATE)

    const [error, setError] = useState('')

    return (
        <div className='loginContainer' >
            <div className='login' >
                <div className='padding'>

                    <div className='logo'>
                        <Icon>send</Icon>
                        Encampment
                    </div>

                    <h4>Log in to your account</h4>

                    {
                        error && (
                            <Error>{ ERROR_MESSAGES[error] }</Error>
                        )
                    }

                    <Form
                        onSubmit={ async ({ state: { inputs }, enableSubmit }) => {

                            try {
                            
                                const { data } = await authenticate({ variables: { payload: inputs } })
                                localStorage.setItem('accessToken', data.authenticate.accessToken)
                                localStorage.setItem('refreshToken', data.authenticate.refreshToken)
                                setAuthenticated(true)
                            
                            }

                            catch (err) {
                                setError(err.graphQLErrors[0].message)
                                enableSubmit()
                            }

                        }}
                    >
                        <Input name='username' label='Username'/>
                        <Input type='password' name='password' label='Password'/>
                        <Submit>Log in</Submit>
                    </Form>
                </div>
            </div>
        </div>
    )

}