import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GET_LINKED_DEVICES,
  LINK_DEVICE,
  REMOVE_DEVICE,
} from "../../operations/MasterAccount";
import Error from "components/Error";

import Form, {
  FormContext,
  Input,
  Select,
  Submit,
} from "../../components/Form";
import Card, { CardTitle, CardIcon } from "components/Card";

import "./link.scss";

const ERROR_MESSAGES = {
  DEVICE_NOT_FOUND:
    "Unable to link device, please double check the security code you entered.",
};

function getIcon(metadata) {
  // ICONS[device.meta.device.type][device.meta.os.name]

  const deviceType =
    metadata && metadata.device && metadata.device.type
      ? metadata.device.type
      : null;
  const os =
    metadata && metadata.os && metadata.os.name ? metadata.os.name : null;

  if (deviceType === "smartphone") {
    if (os === "iOS") return "phone_iphone";
    if (os === "Android") return "phone_android";
    return "smartphone";
  }

  return "device_unknown";
}

export default function Link() {
  const { data } = useQuery(GET_LINKED_DEVICES);

  const [linkDevice, { error, loading }] = useMutation(LINK_DEVICE, {
    refetchQueries: [{ query: GET_LINKED_DEVICES }],
  });

  const [removeDevice, { loading: removeDeviceLoading }] = useMutation(
    REMOVE_DEVICE,
    { refetchQueries: [{ query: GET_LINKED_DEVICES }] }
  );

  return (
    <>
      <Form
        onSubmit={async ({ state: { inputs }, enableSubmit, resetForm }) => {
          try {
            await linkDevice({
              variables: {
                payload: {
                  code: inputs.code.toUpperCase(),
                  // deviceType: inputs.deviceType,
                  deviceType: "CHECK_IN",
                  nickname: inputs.nickname,
                  userType: inputs.userType,
                },
              },
            });

            resetForm();
            enableSubmit();
          } catch (error) {
            enableSubmit();
          }
        }}
      >
        <FormContext.Consumer>
          {([{ inputs }]) => {
            return (
              <>
                <Input
                  name="code"
                  label="Security code"
                  autoComplete="off"
                  regex={/[^0-9A-Za-z]+/g}
                  maxLength={6}
                  uppercase
                />
                <Input name="nickname" label="Device nickname" />
                <Select
                  name="userType"
                  label="User type"
                  options={[
                    ["STANDARD", "Standard user"],
                    ["ADMIN", "Administrator (has access to healthcare info)"],
                  ]}
                  value="STANDARD"
                  noBlankOption
                />
                <Submit disabled={!inputs.code || !inputs.nickname || loading}>
                  Link Device
                </Submit>
                {error && error.graphQLErrors && error.graphQLErrors[0] && (
                  <Error>
                    {ERROR_MESSAGES[error.graphQLErrors[0].message]}
                  </Error>
                )}
              </>
            );
          }}
        </FormContext.Consumer>
      </Form>

      <p>
        Linking new devices: on the device you want to link, open a web browser
        and go to <strong>trc.is/checkin</strong>
      </p>

      {data &&
        data.linkedDevices &&
        data.linkedDevices.map((device) => {
          const brand =
            device.meta && device.meta.device && device.meta.device.brand
              ? device.meta.device.brand
              : "Not detected";
          const model =
            device.meta && device.meta.device && device.meta.device.model
              ? device.meta.device.model
              : "";

          const os =
            device.meta && device.meta.os && device.meta.os.name
              ? device.meta.os.name
              : "Not sdetected";
          const version =
            device.meta && device.meta.os && device.meta.os.version
              ? device.meta.os.version
              : "";

          return (
            <Card key={device.uuid} className="deviceGrid">
              <CardIcon icon={getIcon(device.meta)} />
              <CardTitle title="Model" className="mobileHidden">
                {brand} {model}
              </CardTitle>
              <CardTitle title="OS" className="mobileHidden">
                {os} {version}
              </CardTitle>
              <CardTitle title="Device">{device.nickname}</CardTitle>
              <CardTitle title="User type">{device.userType}</CardTitle>
              <button
                type="button"
                className="deleteDevice"
                disabled={removeDeviceLoading}
                onClick={() => removeDevice({ variables: { id: device.uuid } })}
              >
                <i className="material-icons">delete</i>
              </button>
            </Card>
          );
        })}
    </>
  );
}
