import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Card, { CardTitle, CardCapacity } from '../../components/Card'
import moment from 'moment'
import Grid from '../../components/Grid'
import { GET_SEASONS, GET_PROGRAM_DATES, REGISTER } from '../../operations/Program'
import { GET_REGISTRATIONS_BY_PERSON_ID } from '../../operations/Person'
import { GET_ACCOUNT, GET_ACCOUNT_TRANSACTIONS } from '../../operations/Account'
import Form, { FormContext, Input, Select } from '../../components/Form'

const breadcrumbs = {
    padding: '2rem 0'
}

export default function Register({ account, person, onComplete }) {

    const { loading, error, data } = useQuery(GET_SEASONS)
    const [season, setSeason] = useState()
    const [category, setCategory] = useState()
    const [program, setProgram] = useState()

    if (loading) return 'Loading...'
    if (error) return 'Error'

    if (!season) return (
        <>
            <h1>Select a Season</h1>
            {
                data.seasons.map(data => {
                    const { id, name } = data
                    return (
                        <Card 
                            key={id}
                            onClick={() => setSeason(data)}
                        >
                            { name }
                        </Card>
                    )
                })
            }
        </>
    )

    if (!category) {

        return (
            <>
                <div style={breadcrumbs} >{ season.name }</div>
                <h1>Select a Category</h1>
                {
                    data.seasons.find(({ id }) => season.id === id).categories.map(data => {
                        const { id, name } = data
                        return (
                            <Card 
                                key={ id }
                                onClick={() => setCategory(data)}
                            >
                                { name }
                            </Card>
                        )
                    })
                }
            </>
        )
    }

    if (!program) {

        return (
            <>
                <div style={breadcrumbs} >{ season.name } > { category.name }</div>
                <h1>Select a Program</h1>
                {
                    data.seasons.find(({ id }) => season.id === id).categories.find(({ id }) => category.id === id).programs.map(data => {
                        const { id, name } = data
                        return (
                            <Card 
                                key={ id }
                                onClick={() => setProgram(data)}
                            >
                                { name }
                            </Card>
                        )
                    })                    
                }
            </>
        )

    }

    const programData = data.seasons.find(({ id }) => season.id === id).categories.find(({ id }) => category.id === id).programs.find(({ id }) => program.id === id)

    return (
        <>
            <div style={breadcrumbs} >{ season.name } > { category.name } > { program.name }</div>
            <h1>Select an Event</h1>
            <ProgramDates season={ season } category={ category } program={ programData } account={ account } person={ person } onComplete={() => { if (onComplete) onComplete() }} />
        </>
    )

}

function ProgramDates({ season, category, program, account, person, onComplete }) {
    
    const { loading, error, data } = useQuery(GET_PROGRAM_DATES, { 
        variables: { 
            filter: { 
                programId: { eq: program.id },
                endDate: { gte: moment().format('YYYY-MM-DD') }
            } 
        } 
    })

    const [register] = useMutation(REGISTER, { refetchQueries: [
        { query: GET_REGISTRATIONS_BY_PERSON_ID, variables: { id: person.id } },
        { query: GET_ACCOUNT, variables: { id: account.id }  },
        { query: GET_ACCOUNT_TRANSACTIONS, variables: { id: account.id } },
        { query: GET_PROGRAM_DATES, variables: { filter: { 
            programId: { eq: program.id },
            endDate: { gte: moment().format('YYYY-MM-DD') }
        } } }
    ] })

    if (loading) return 'Loading...'
    if (error) return `Error: ${error.message}`

    // TODO: Move program options into the database
    // BMX = 12
    // Intensive = 9

    console.log(program)

    return (
        <Form>
            <FormContext.Consumer>
                {([{ inputs }]) => { 
                    console.log(inputs)
                    return(
                    <>
                        {
                            program.allowSnackbar && (
                                <Input name='snackbar' inputMode='decimal' label='Snackbar Amount' />
                            )
                        }
                        {
                            program.id === '12' && (
                                <Select name='bmx' label='Bike &amp; Helmet' options={[['bring', `Will Bring`], ['rent', 'Rent Them - $50.00 +HST']]} />
                            )
                        }
                        {
                            program.id === '9' && (
                                <Select name='board' label='Horse Board' options={[['requires_board', `Horse Boarding - $120.00 +HST`], ['no_board', 'Using a Teen Ranch Horse']]} />
                            )
                        }
                        {
                            (category.id === '1' || category.id === '11') && (
                                <Select name='hockey' label='Player/Goalie' options={[['player', `Player`], ['goalie', 'Goalie']]} />
                            )
                        }
                        {
                            season.id === '1' && (
                                <Select name='airport' label='Airport Pickup' options={[['arrival', `Arrival - $100.00 +HST`], ['departure', 'Departure - $100.00 +HST'], ['both_ways', 'Arrival & Departure - $200.00 +HST'], ['none', 'No Pickup']]} />
                            )
                        }
                        {
                
                            Object.keys(inputs).every(key => inputs[key].length > 0) && data.programDates.map(date => {
                                return (
                                    <Card
                                        key={ date.id }
                                        onClick={ async () => {

                                            const options = []

                                            const optionsObject = {
                                                bmx: {
                                                    title: 'Bike & Helmet',
                                                    values: {
                                                        bring: {
                                                            value: `I'll Bring Them`,
                                                            price: 0
                                                        },
                                                        rent: {
                                                            value: 'Rent',
                                                            price: 50
                                                        }
                                                    }
                                                },
                                                board: {
                                                    title: 'Horse Board',
                                                    values: {
                                                        requires_board: {
                                                            value: 'Requires Board',
                                                            price: 120
                                                        },
                                                        no_board: {
                                                            value: 'No Board',
                                                            price: 0
                                                        }
                                                    }
                                                },
                                                hockey: {
                                                    title: 'Player/Goalie',
                                                    values: {
                                                        player: {
                                                            value: 'Player',
                                                            price: 0
                                                        },
                                                        goalie: {
                                                            value: 'Goalie',
                                                            price: 0
                                                        }
                                                    }
                                                },
                                                airport: {
                                                    title: 'Airport Pickup',
                                                    values: {
                                                        arrival: {
                                                            value: 'Arrival',
                                                            price: 100
                                                        },
                                                        departure: {
                                                            value: 'Departure',
                                                            price: 100
                                                        },
                                                        both_ways: {
                                                            value: 'Both Ways',
                                                            price: 200
                                                        },
                                                        none: {
                                                            value: 'No Pickup',
                                                            price: 0
                                                        }
                                                    }
                                                }
                                            }

                                            Object.keys(inputs).map(key => {
                                                if (key === 'snackbar') return null
                                                return options.push({
                                                    optionTitle: optionsObject[key].title,
                                                    optionKey: key, 
                                                    optionValue: optionsObject[key].values[inputs[key]].value,
                                                    optionPrice: optionsObject[key].values[inputs[key]].price
                                                })
                                            })

                                            try {
                                            
                                                await register({
                                                    variables: {
                                                        payload: {
                                                            accountId: account.id,
                                                            personId: person.id,
                                                            seasonId: season.id,
                                                            categoryId: category.id,
                                                            programId: program.id,
                                                            programDateId: date.id,
                                                            subtotal: date.price > 0 ? date.price : program.price,
                                                            snackbar: inputs.snackbar ? Number(inputs.snackbar) : 0,
                                                            options
                                                        }
                                                    }
                                                })

                                                if (onComplete) onComplete()

                                            }
                                            catch (err) {
                                                console.log(err)
                                            }

                                        }}
                                    >
                                        <Grid 
                                            style={{ 
                                                gridTemplateColumns: `
                                                    minmax(300px, 2fr)
                                                    minmax(300px, 2fr)
                                                    minmax(300px, 2fr)
                                                    minmax(60px, 0.4fr)
                                                    minmax(60px, 0.5fr)
                                                ` 
                                            }}
                                        >
                                            <CardTitle title='Name'>
                                                { date.customName || program.name }
                                            </CardTitle>
                                            <CardTitle title='Start Date'>
                                                { moment(date.startDate).format('dddd, MMMM D, YYYY') }
                                            </CardTitle>
                                            <CardTitle title='End Date'>
                                                { moment(date.endDate).format('dddd, MMMM D, YYYY') }
                                            </CardTitle>
                                            <CardTitle title='Age' right>
                                                { date.ageMin } - { date.ageMax }
                                            </CardTitle>
                                            <CardTitle title='Capacity' right>
                                                { date.currentCapacity } / { date.capacity }
                                            </CardTitle>

                                            <CardCapacity currentCapacity={ date.currentCapacity } capacity={ date.capacity } />
                                        </Grid>
                                    </Card>
                                )
                            })
                        }
                    </>
                )}}
            </FormContext.Consumer>
        </Form>
    )

}