import gql from 'graphql-tag'

export const UPDATE_PERSON_BY_ID = gql`
    mutation UpdatePerson($payload: UpdatePersonPayload!) {
        updatePerson(payload: $payload) {
            id
        }
    }
`

export const CREATE_PERSON = gql`
    mutation CreatePerson($payload: CreatePersonPayload!) {
        createPerson(payload: $payload) {
            id
        }
    }
`

export const GET_REGISTRATIONS_BY_PERSON_ID = gql`
    query RegistrationsByPersonId($id: ID!) {
        registrations: registrationsByPersonId(id: $id) {
            id
            season {
                id
                name
            }
            category {
                id
                name
            }
            program {
                id
                name
            }
            programDate {
                id
                startDate
                endDate
                customName
            }
            friendRequests {
                fullName
            }
            snackbarAccount {
                id
                amount
                notRefundable
                depositType
                transactionDate
            }
        }
    }
`