import React, { useState, useEffect } from 'react'
import { useForm } from './Form'
import InputContainer from './InputContainer'

export default function Textarea({ label, name, value = '', type = 'text', onChange, placeholder, submitOnEnter }) {

    const [isFocused, setFocused] = useState(false)

    const [{ inputs }, setInput, submit] = useForm()

    // Init the input
    useEffect(() => {
        if (typeof inputs[name] === 'undefined') {
            setInput({[name]: value})
        }
    }, [inputs, name, setInput, value])

    const inputId = `f_${name}`

    return (
        <InputContainer label={ label } inputId={ inputId } isFocused={ isFocused } isFilled={ inputs[name] }>
            <textarea
                id={ inputId }
                type={ type }
                name={ name }
                onChange={e => {
                    setInput({
                        [name]: e.target.value
                    })
                    if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
                }}
                onFocus={ () => setFocused(true) }
                onBlur={ () => setFocused(false) }
                value={ inputs[name] || '' }
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && !e.shiftKey && submitOnEnter) {
                        submit()
                    }
                }}
            />                
        </InputContainer>
    )

}