import React from "react";
import { Link } from "@reach/router";
import "./button.scss";

export default function Button({
  children,
  onClick,
  to,
  material,
  unstyled,
  className,
}) {
  let El = "button";
  if (to) El = Link;

  return (
    <El
      className={`button ${material ? "button--material" : ""} ${
        unstyled ? "button--unstyled" : ""
      } ${className ? className : ""}`}
      type={El === "button" ? "button" : undefined}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      to={to}
    >
      {children}
    </El>
  );
}
