import {
    CONTAINS,
    DOES_NOT_CONTAIN,
    IS,
    IS_NOT,
    IS_LESS_THAN,
    IS_LESS_THAN_OR_EQUAL,
    IS_GREATER_THAN
} from './operators'

import {
    DATE,
    SEASON,
    REGISTRANT,
    REGISTERED,
    CURRENT_YEAR_SEASON
} from './parameters'


const conditions = [
    {
        ...DATE,
        fields: [
            IS_LESS_THAN,
            IS_LESS_THAN_OR_EQUAL,
            IS_GREATER_THAN,
            IS_LESS_THAN_OR_EQUAL
        ]
    },
    {
        ...SEASON,
        fields: [
            CONTAINS,
            DOES_NOT_CONTAIN
        ]
    },
    {
        ...REGISTRANT,
        fields: [
            {
                ...IS_NOT,
                fields: [
                    {
                        ...REGISTERED,
                        fields: [
                            CURRENT_YEAR_SEASON
                        ]
                    }
                ]
            },
            {
                ...IS,
                fields: [
                    {
                        ...REGISTERED,
                        fields: [
                            CURRENT_YEAR_SEASON
                        ]
                    }
                ]
            }
        ]
    }
]

export {
    conditions
}