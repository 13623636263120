import React, { createContext, useContext, useState, useEffect } from 'react'

const FormContext = createContext()

export default function Form({
    children,
    onChange,
    onSubmit,
    init = {}
}) {

    const [state, setState] = useState({
        submitted: false,
        inputs: init
    })

    useEffect(() => {
        if (onChange) {
            onChange(state)
        }
    })

    function updateInputState(input) {

        setState(state => ({
            ...state,
            inputs: { ...state.inputs, ...input }
        }))

    }

    function handleFormReset() {
        setState(state => ({
            ...state,
            inputs: init
        }))
    }

    function handleClearForm() {
        setState(state => ({
            ...state,
            inputs: {}
        }))
    }

    function handleEnableSubmit() {
        setState(state => ({
            ...state,
            submitted: false
        }))
    }

    function submitForm() {
        setState(state => ({
            ...state,
            submitted: true
        }))

        if (onSubmit) onSubmit({
            state,
            resetForm: handleFormReset,
            clearForm: handleClearForm,
            enableSubmit: handleEnableSubmit
        })
    }

    return (
        <FormContext.Provider
            value={[
                state,
                (input) => updateInputState(input),
                () => submitForm()
            ]}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault()

                    submitForm()

                }}
            >
                { children }
            </form>
        </FormContext.Provider>
    )

}

const useForm = () => useContext(FormContext)
export {
    useForm,
    FormContext
}