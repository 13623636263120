import React from 'react'
import { Link } from '@reach/router'
import moment from 'moment'

import './card.scss'

const EXCEPTIONED_INITIALS = [
    'BS',
    'VD',
    'FU',
    'BJ',
    'MF'
]

export default function Card({ children, onClick, to, style, indent, className, deleted }) {

    let El = 'div'
    if (onClick) El = 'div'
    if (to) El = Link

   

    return (
        <El 
            style={style}
            className={`card ${indent ? 'card--indent' : ''} ${ onClick ? 'card--clickable' : '' } ${ className ? className : '' } ${deleted ? 'card--deleted':''}`}
            onClick={(e) => {
                if (onClick) onClick(e)
            }}
            to={ to }
            type={ El === 'button' ? 'button' : undefined }
        >
            { children }
        </El>
    )

}

export function CardTitle({ title, children, style, right, to, className = '' }) {

    let El = 'div'
    if (to) El = Link

    return (
        <El className={`cardSection ${ right ? 'cardSection--right' : '' } ${ className }`} style={ style }  to={ to }>
            <div className='cardTitle'>{ title }</div>
            { children }
        </El>
    )

}

export function CardDate({ children, tense }) {
    return (
        <div className={`cardDate ${ tense ? `cardDate--${tense}` : '' }`}>
            { moment(children).format('MMM') }<br/>
            { moment(children).format('D') }
        </div>
    )
}

export function CardProfile({ person }) {

    const firstInitial = person.firstName.substr(0, 1)
    const lastInitial = person.lastName.substr(0, 1)

    const initials = EXCEPTIONED_INITIALS.find((exception) => exception === firstInitial + lastInitial) ? firstInitial : firstInitial + lastInitial

    return (
        <div className={`cardProfile ${ person.gender ? `cardProfile--${ person.gender.toLowerCase() }` : '' }`}>
            { initials }
        </div>
    )

}

export function CardIcon({ icon }) {
    return <div className='cardIcon'><i className='material-icons'>{ icon }</i></div>
}

export function CardCapacity({ currentCapacity, capacity }) {
    
    const progress = currentCapacity / capacity * 100

    let className = 'green'
    if (progress > 75) className = 'yellow'
    if (progress > 90) className = 'orange'
    if (progress === 100) className = 'red'

    return (
        <div className='cardCapacityContainer'>
            <div className={`cardCapacity cardCapacity--${ className }`} style={{ width: `${ progress }%` }} />
        </div>
    )
}

export function CardLine({ type }) {
    return <div className={`cardLine cardLine--${ type }`} />
}

export function CardHR() {
    return <div className='cardHR' />
}