import React, { Fragment, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { REVENUE } from "../../operations/Transaction";
import { useDateRange } from "../../hooks/dateRange";
import { LayoutHeader } from "../../components/Layout";
import Calendar from "react-calendar";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";
import Scroll from "../../components/Scroll";
import Card from "../../components/Card";
import Grid from "../../components/Grid";
import Format from "../../components/Format";
import { GET_NPS_SCORE } from "../../operations/Nps";
import { GET_SUMMER_CAMP_DASHBOARD } from "operations/Program";
import { compareAsc, getWeek, intlFormat, parseISO } from "date-fns";

export default function Dashboard() {
  const { startDate, setStartDate, endDate, setEndDate } = useDateRange();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const { loading, error, data } = useQuery(REVENUE, {
    variables: {
      current: {
        paymentDate: {
          gte: moment(startDate).format("YYYY-MM-DD"),
          lte: moment(endDate).format("YYYY-MM-DD"),
        },
      },
      comparison: {
        paymentDate: {
          gte: moment(startDate).subtract(1, "y").format("YYYY-MM-DD"),
          lte: moment(endDate).subtract(1, "y").format("YYYY-MM-DD"),
        },
      },
    },
  });

  if (loading) return "Loading...";
  if (error) return "Error";

  const chartData = [
    {
      id: "Current Period",
      data: [],
    },
    {
      id: "Comparison Period",
      data: [],
    },
  ];

  const dates = [];
  let currentDate = moment(startDate);
  while (currentDate < moment(endDate)) {
    currentDate = moment(currentDate).add(1, "days");
    dates.push(currentDate);
  }

  let currentRevenue = 0;
  let comparisonRevenue = 0;

  // const currentYear = moment(startDate).year()
  // const comparisonYear = moment(startDate).subtract(1, 'y').year()

  dates.map((date) => {
    const currentDate = moment(date).format("YYYY-MM-DD");
    const currentDataDate = data.current.find(
      (date) => date.date === currentDate
    );
    chartData[0].data.push({
      x: currentDate,
      y: currentDataDate ? currentDataDate.revenue : 0,
    });
    if (currentDataDate) currentRevenue += currentDataDate.revenue;

    const comparisonDate = moment(date).subtract(1, "y").format("YYYY-MM-DD");
    const comparisonDataDate = data.comparison.find(
      (date) => date.date === comparisonDate
    );
    chartData[1].data.push({
      x: currentDate,
      y: comparisonDataDate ? comparisonDataDate.revenue : 0,
    });
    if (comparisonDataDate) comparisonRevenue += comparisonDataDate.revenue;

    return true;
  });

  return (
    <>
      <LayoutHeader>
        <Breadcrumb
          title="Date Range"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div onClick={() => setCalendarOpen(!calendarOpen)}>
            {startDate.format("MMM D, YYYY")} - {endDate.format("MMM D, YYYY")}
          </div>
          {calendarOpen && (
            <div style={{ position: "absolute", background: "#fff" }}>
              <Calendar
                selectRange
                calendarType="US"
                value={[new Date(startDate), new Date(endDate)]}
                onChange={([startDate, endDate]) => {
                  setStartDate(moment(startDate));
                  setEndDate(moment(endDate));
                  setCalendarOpen(false);
                }}
              />
            </div>
          )}
        </Breadcrumb>
      </LayoutHeader>
      <Scroll>
        <Grid style={{ gridTemplateColumns: "auto auto" }}>
          <h1>Dashboard</h1>
          <p style={{ textAlign: "right" }}>
            <strong>Current Period Revenue:</strong>{" "}
            <Format type="currency" value={currentRevenue} />
            <br />
            <strong>Comparison Period Revenue:</strong>{" "}
            <Format type="currency" value={comparisonRevenue} />
          </p>
        </Grid>

        <Grid style={{ gridTemplateColumns: "2fr 1fr" }}>
          <Card style={{ height: "480px" }}>
            <MyResponsiveLine data={chartData} />
          </Card>
          <Card>
            <NpsScore />
          </Card>
        </Grid>
      </Scroll>
      <SummerCamp />
    </>
  );
}

function MyResponsiveLine({ data }) {
  return (
    <ResponsiveLine
      data={data}
      xScale={{ type: "point" }}
      yScale={{ type: "linear", stacked: false, min: "auto", max: "auto" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Date",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Revenue",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      margin={{
        top: 50,
        right: 60,
        left: 60,
        bottom: 100,
      }}
      colors={{ scheme: "set2" }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      enableArea={false}
      enablePoints={false}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 80,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 140,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

function NpsScore() {
  const { loading, error, data } = useQuery(GET_NPS_SCORE);

  if (loading) return "Loading...";
  if (error) return "Error...";

  const { nps } = data;

  return (
    <div className="npsScore dashboard">
      <h1>NPS Score</h1>
      {nps.score}
    </div>
  );
}

function Link({ to, children }) {
  return <a href={to}>{children}</a>;
}

function SummerCamp() {
  const { error, loading, data } = useQuery(GET_SUMMER_CAMP_DASHBOARD);

  const [display, setDisplay] = useState("default");

  console.log(error, loading, data);

  if (error || loading) return null;

  const summerCampPrograms = data.getDashboardRegistrations_summerCamp;

  const currentCapacityTotal =
    summerCampPrograms &&
    summerCampPrograms.weeks &&
    summerCampPrograms.weeks.reduce(
      (total, current) => (total += current.currentCapacity),
      0
    );

  const capacityTotal =
    summerCampPrograms &&
    summerCampPrograms.weeks &&
    summerCampPrograms.weeks.reduce(
      (total, current) => (total += current.capacity),
      0
    );

  const capacityPercentage = Math.round(
    (currentCapacityTotal / capacityTotal) * 100
  );

  const weekIsUdc = [true,false,false,false,false,false,false, true, true]

  return (
    <div className="tw-p-8 tw-overflow-y-scroll tw-scrollbar-thin tw-scrollbar-thumb-slate-400 tw-scrollbar-track-white tw-scrollbar-thumb-rounded tw-pb-8">
      <div className="tw-flex tw-gap-4 tw-text-md tw-border tw-bg-slate-100 tw-rounded tw-py-1 tw-px-2 tw-mb-2 tw-items-center">
        <strong className="text-xs uppercase">Display:</strong>
        <button
          type="button"
          onClick={() => setDisplay("default")}
          className={`${display === "default" ? "tw-underline" : ""}`}
        >
          Default
        </button>
        <button
          type="button"
          onClick={() => setDisplay("age")}
          className={`${display === "age" ? "tw-underline" : ""}`}
        >
          Age
        </button>
        <button
          type="button"
          onClick={() => setDisplay("capacity")}
          className={`${display === "capacity" ? "tw-underline" : ""}`}
        >
          Capacity
        </button>
      </div>
      <div className="">
        <div
          className={`tw-grid tw-border`}
          style={{
            gridTemplateColumns: `repeat(${
              summerCampPrograms.weeks.length + 1
            }, 1fr)`,
          }}
        >
          <div className="tw-flex tw-items-center tw-justify-center tw-text-center tw-text-md tw-uppercase">
            Program / Date
          </div>
          {summerCampPrograms &&
            summerCampPrograms.weeks &&
            summerCampPrograms.weeks.map((week, i) => {
              const isUdc = weekIsUdc[i]
              const startMonth = intlFormat(parseISO(week.startDate), {
                month: "short",
              });
              const endMonth = intlFormat(parseISO(week.endDate), {
                month: "short",
              });
              return (
                <div
                  key={i}
                  className={`tw-border-l tw-flex tw-items-center tw-justify-center tw-py-1 ${
                    isUdc ? "tw-bg-blue-300" : "tw-bg-green-300"
                  }`}
                >
                  {startMonth}{" "}
                  {intlFormat(parseISO(week.startDate), { day: "numeric" })}
                  {endMonth === startMonth ? "-" : " - "}
                  {endMonth !== startMonth ? endMonth : ""}
                  {endMonth === startMonth
                    ? intlFormat(parseISO(week.endDate), { day: "numeric" })
                    : ` ${intlFormat(parseISO(week.endDate), {
                        day: "numeric",
                      })}`}
                </div>
              );
            })}
        </div>
        {summerCampPrograms &&
          summerCampPrograms.summerCamp &&
          summerCampPrograms.summerCamp.categories &&
          summerCampPrograms.summerCamp.categories.map(
            (category, categoryIndex) => {
              const programs = category.programs;
              // return (
              //   <div key={}></div>
              // )
              return (
                <Fragment key={categoryIndex}>
                  {programs.map((program, programIndex) => {
                    let populatedWeekIndex = 0;

                    return (
                      <div
                        key={programIndex}
                        className={`tw-grid tw-border-r`}
                        style={{
                          gridTemplateColumns: `repeat(${
                            summerCampPrograms.weeks.length + 1
                          }, 1fr)`,
                        }}
                      >
                        <div
                          className={`tw-border-l tw-border-b tw-text-white tw-text-md tw-p-1 ${
                            program.name.includes("Girl")
                              ? "tw-bg-green-600"
                              : program.name.includes("Goalie")
                              ? "tw-bg-lime-600"
                              : program.name.includes("Western")
                              ? "tw-bg-purple-600"
                              : program.name.includes("English")
                              ? "tw-bg-pink-600"
                              : program.name.includes("WHAM")
                              ? "tw-bg-orange-600"
                              : program.name.includes("Adventure")
                              ? "tw-bg-yellow-600"
                              : program.name.includes("Elite")
                              ? "tw-bg-teal-600"
                              : program.name.includes("Intensive")
                              ? "tw-bg-violet-600"
                              : program.name.includes("Hockey")
                              ? "tw-bg-emerald-600"
                              : "tw-bg-slate-800"
                          }`}
                        >
                          <div className="tw-flex tw-items-center tw-justify-center tw-text-center tw-h-full">
                            {/* {category.name} */}
                            {program.name}{" "}
                            {category.name.includes("Overnight")
                              ? "(ONC)"
                              : category.name.includes("Ultimate")
                              ? "(UDC)"
                              : ""}
                          </div>
                        </div>
                        {program &&
                          program.programDates &&
                          program.programDates
                            .sort((a, b) =>
                              compareAsc(
                                parseISO(a.startDate),
                                parseISO(b.startDate)
                              )
                            )
                            .map((programDate, programDateIndex) => {
                              const weekNumber = getWeek(
                                parseISO(programDate.startDate)
                              );
                              const weekIndex =
                                summerCampPrograms.weeks.findIndex(
                                  (current) =>
                                    current.weekNumber === String(weekNumber)
                                );

                              const difference = weekIndex - populatedWeekIndex;
                              const currentPopulatedWeekIndex =
                                populatedWeekIndex;

                              populatedWeekIndex +=
                                difference > 0 ? difference + 1 : 1;

                              const endDifference =
                                summerCampPrograms.weeks.length - weekIndex - 1;

                              const capacityPercentage = Math.round(
                                (programDate.currentCapacity /
                                  programDate.capacity) *
                                  100
                              );

                              return (
                                <Fragment key={programDateIndex}>
                                  {[...Array(difference)].map((index, i) => {
                                    // const week =
                                    //   summerCampPrograms.weeks[
                                    //     currentPopulatedWeekIndex + i
                                    //   ];

                                    const isUdc = weekIsUdc[currentPopulatedWeekIndex+i]
                                    return (
                                      <div
                                        key={i}
                                        className={`tw-border-l  tw-border-b ${
                                          isUdc
                                            ? "tw-bg-blue-100"
                                            : "tw-bg-green-100 "
                                        }`}
                                      ></div>
                                    );
                                  })}
                                  {programDateIndex !== populatedWeekIndex &&
                                    []}
                                  <div className="tw-flex tw-items-center tw-justify-center tw-border-l tw-text-md tw-border-b">
                                    {/* <div className="text-center p-1"> */}
                                    <Link
                                      to={`/program-date/${program.id}/${programDate.id}`}
                                      className="tw-border tw-text-center tw-p-1 tw-block tw-w-full tw-border-transparent tw-hover:border-red-300"
                                    >
                                      {display !== "capacity" && (
                                        <div>
                                          <span className="tw-text-slate-500 tw-text-md">
                                            Age:
                                          </span>{" "}
                                          {programDate.ageMin}-
                                          {programDate.ageMax}
                                        </div>
                                      )}
                                      {display !== "age" && (
                                        <div
                                          className={`tw-text-md ${
                                            capacityPercentage === 100
                                              ? "tw-text-red-600"
                                              : capacityPercentage > 90
                                              ? "tw-text-orange-600"
                                              : capacityPercentage > 80
                                              ? "tw-text-yellow-600"
                                              : "tw-text-blue-600"
                                          }`}
                                        >
                                          <span className="tw-text-lg">
                                            {programDate.currentCapacity}
                                          </span>
                                          /{programDate.capacity} (
                                          {capacityPercentage}
                                          %)
                                        </div>
                                      )}
                                    </Link>
                                    {/* </div> */}
                                  </div>
                                  {programDateIndex ===
                                    program.programDates.length - 1 &&
                                    endDifference > 0 &&
                                    [...Array(endDifference)].map(
                                      (index, i) => {
                                        // const week =
                                        //   summerCampPrograms.weeks[
                                        //     populatedWeekIndex + i
                                        //   ];
                                        
                                    const isUdc = weekIsUdc[populatedWeekIndex+i]
                                        return (
                                          <div
                                            key={i}
                                            className={`tw-border-l tw-border-b ${
                                              isUdc
                                                ? "tw-bg-blue-100"
                                                : "tw-bg-green-100"
                                            }`}
                                          ></div>
                                        );
                                      }
                                    )}
                                </Fragment>
                              );
                            })}
                      </div>
                    );
                  })}
                </Fragment>
              );
            }
          )}
        <div
          className={`tw-grid tw-border-r`}
          style={{
            gridTemplateColumns: `repeat(${
              summerCampPrograms.weeks.length + 1
            }, 1fr)`,
          }}
        >
          <div className="tw-flex tw-items-center tw-justify-center tw-border-l tw-border-b">
            <div className="tw-text-center">
              <div className="tw-text-md tw-uppercase">Totals </div>
              <div
                className={`tw-text-md ${
                  capacityPercentage === 100
                    ? "tw-text-red-600"
                    : capacityPercentage > 90
                    ? "tw-text-orange-600"
                    : capacityPercentage > 80
                    ? "tw-text-yellow-600"
                    : "tw-text-blue-600"
                }`}
              >
                <span className="tw-text-lg">{currentCapacityTotal}</span>/
                {capacityTotal} ({capacityPercentage}
                %)
              </div>
            </div>
          </div>
          {summerCampPrograms &&
            summerCampPrograms.weeks &&
            summerCampPrograms.weeks.map((week, i) => {
              const capacityPercentage = Math.round(
                (week.currentCapacity / week.capacity) * 100
              );
              const isUdc = weekIsUdc[i]
              return (
                <div
                  key={i}
                  className={`tw-border-l tw-border-b tw-flex tw-items-center tw-justify-center tw-py-1 ${
                    isUdc ? "tw-bg-blue-300" : "tw-bg-green-300"
                  }`}
                >
                  <div
                    className={`tw-text-md ${
                      capacityPercentage === 100
                        ? "tw-text-red-600"
                        : capacityPercentage > 90
                        ? "tw-text-orange-600"
                        : capacityPercentage > 80
                        ? "tw-text-yellow-600"
                        : "tw-text-blue-600"
                    }`}
                  >
                    <span className="tw-text-lg">{week.currentCapacity}</span>/
                    {week.capacity} ({capacityPercentage}
                    %)
                  </div>
                </div>
              );
            })}
        </div>

        {/* {[...new Array(52).keys()].map((week) => {
          return <div>{week}</div>;
        })} */}
      </div>
      {/* <div>{debug(summerCampPrograms)}</div> */}
    </div>
  );
}
