import gql from "graphql-tag";

export const REGISTRATION_DATA = gql`
  fragment RegistrationParts on Registration {
    id
    cost
    subtotal
    discount
    tax
    snackbar
    total
    registrationDate
    isCancelled
    notes
    season {
      id
      name
    }
    category {
      id
      name
    }
    program {
      id
      name
    }
    programDate {
      id
      startDate
      endDate
      customName
    }
    person {
      id
      firstName
      lastName
    }
    options {
      optionTitle
      optionKey
      optionValue
      optionPrice
    }
    discountInfo {
      id
      discountType
      description
      discountPercentage
      discountMultiplier
      discountStart
      discountEnd
      discountCode
      status
    }
  }
`;

export const FRIEND_REQUEST = gql`
  mutation FriendRequest($payload: FriendRequestPayload!) {
    friendRequest(payload: $payload)
  }
`;

export const SNACKBAR_BONUS = gql`
  mutation SnackbarBonus($payload: SnackbarBonusPayload!) {
    addSnackbarBonus(payload: $payload)
  }
`;

export const CANCEL_REGISTRATION = gql`
  mutation CancelRegistration($payload: CancelRegistrationPayload!) {
    cancelRegistration(payload: $payload)
  }
`;

export const CHECK_IN_OUT = gql`
  mutation CheckIn($payload: RegistrationCheckInOutPayload!) {
    registrationCheckInOut(payload: $payload) {
      id
      isCheckedIn
      isCheckedOut
    }
  }
`;

export const REVERT_CHECK_IN_OUT = gql`
  mutation CheckIn($payload: RegistrationCheckInOutPayload!) {
    registrationCheckInOutRevert(payload: $payload) {
      id
      isCheckedIn
      isCheckedOut
    }
  }
`;
