import { useQuery } from '@apollo/react-hooks'
import Card, { CardIcon } from 'components/Card'
import { DateTime } from 'luxon'
import { GET_COVID_SCREENING } from 'operations/Account'
import React, { useState } from 'react'

const COVID_SCREENING_ICONS = {
    UNKNOWN: 'help_outline',
    PASS: 'check_circle',
    FAIL: 'do_not_disturb'
}

const COVID_RESULT_TEXT = {
    UNKNOWN: 'Incomplete',
    PASS: 'Pass',
    FAIL: 'Fail'
}

const QUESTIONS = {
    travelWithin14: {
        title: 'In the last 14 days, have your children or anyone they live with travelled outside of Canada?',
        subtitle: 'If exempt from quarantine requirements (for example, an essential worker who crosses the Canada-US border regularly for work), select "No".'
    },
    doctorRecommendedIsolation: {
        title: 'Has a doctor, health care provider, or public health unit told you that your child should be isolating (staying at home)?',
        subtitle: 'This can be because of an outbreak or contact tracing.'
    },
    closeContact: {
        title: 'In the last 14 days, has your child been identified as a "close contact" of someone who currently has COVID-19?',
        subtitle: ''
    },
    covidAlert: {
        title: 'In the last 14 days, has your child received a COVID Alert exposure notification on a cell phone?',
        subtitle: 'If they already received a negative test result, select "No".'
    },
    feverChills: {
        title: 'Fever and/or chills',
        subtitle: 'Temperature of 37.8℃ / 100℉ or higher.'
    },
    cough: {
        title: 'Cough or barking cough (croup)',
        subtitle: 'Continuous, more than usual, making a whistling noise when breathing (not related to asthma, post-infectious reactive airways, or other known causes or conditions they already have).'
    },
    shortnessOfBreath: {
        title: 'Shortness of breath',
        subtitle: 'Out of breath, unable to breath deeply (not related to asthma or other known causes or conditions they already have).'
    },
    tasteAndSmell: {
        title: 'Decrease or loss of taste or smell',
        subtitle: 'Not related to seasonal allergies, neurological disorders, or other known causes or conditions they already have.'
    },
    soreThroat: {
        title: 'Sore throat or difficulty swallowing',
        subtitle: 'Painful swallowing (not related to seasonal allergies, acid reflux, or other known causes or conditions they already have).'
    },
    nose: {
        title: 'Runny or stuffy/congested nose',
        subtitle: 'Not related to seasonal allergies, being outside in cold weather, or other known causes or conditions they already have).'
    },
    headache: {
        title: 'Headache',
        subtitle: 'Unusual, long-lasting (not related to tension-type headaches, chronic migraines, or other known causes or conditions they already have).'
    },
    nausea: {
        title: 'Nausea, vomiting and/or diarrhea',
        subtitle: 'Not related to irritable bowel syndrome, anxiety, menstrual cramps, or other known causes or conditions they already have).'
    },
    extremeTiredness: {
        title: 'Extreme tiredness or muscle aches',
        subtitle: 'Unusual fatigue, lack of energy, poor feeding in infants (not related to depression, insomnia, thyroid dysfunction, sudden injury, or other known causes or conditions they already have).'
    },
    sameHouse: {
        title: 'Is someone in the same household currently experiencing any new COVID-19 symptoms and/or waiting for test results after experiencing symptoms?',
        subtitle: 'If the person got a COVID-19 vaccine in the last 48 hours and is experiencing a mild headache, fatigue, muscle aches, and/or joint pain that only began after vaccination, select "No".'
    },
    vaccines: {
        title: 'Are they up to date on their vaccines, including COVID-19?',
        subtitle: 'Does not affect summer camp eligibility.'
    },
}

export default function CovidScreening({ accountId }) {

    const [ today ] = useState(() => DateTime.now().toISODate())

    const { error, loading, data } = useQuery(GET_COVID_SCREENING, { variables: { accountId, today } })

    if (error) return 'Error loading covid screening data.'
    if (loading) return 'Loading...'

    return (
        <>
            <h3>Covid screening</h3>
            <Card
                className={`covidScreeningResult covidScreeningResult--${ data.covidScreening.covidScreeningResult } dailyCovidScreeningResult--${ data.covidScreening.dailyCovidScreeningResult }`}
            >
                <CardIcon icon={ COVID_SCREENING_ICONS[data.covidScreening.covidScreeningResult] } />
                <div><strong>Online form:</strong> { COVID_RESULT_TEXT[data.covidScreening.covidScreeningResult] }</div>
                <div><strong>Daily check:</strong> { COVID_RESULT_TEXT[data.covidScreening.dailyCovidScreeningResult] }</div>
            </Card>
            {
                data.covidScreening.covidForm && (
                    <>
                        {
                            Object.keys(data.covidScreening.covidForm.answers).map(key => {
                                const answer = data.covidScreening.covidForm.answers[key]
                                return (
                                    <Card key={key}>
                                        <h4>{ QUESTIONS[key].title }</h4>
                                        <h5 style={{ fontWeight: 'normal' }}>{ QUESTIONS[key].subtitle }</h5>
                                        <strong>Answer:</strong> {answer}
                                    </Card>
                                )
                            })
                        }
                        <Card>
                            <h4>Pre-existing conditions (seasonal allergies, headaches, etc.)</h4>
                            { data.covidScreening.covidForm.inputs.preExistingConditions || 'NONE' }
                        </Card>

                        <Card>
                            <h4>Signed</h4>
                            { data.covidScreening.covidForm.inputs.writtenAttestation || 'Done at check-in' }
                        </Card>
                    </>
                )
            }
        </>
    )

}