import React, { createContext, useContext, useState } from 'react'
import AccountCreatedInPerson from '../subscriptions/AccountCreatedInPerson'

const NotificationContext = createContext()

export default function Notification({ children }) {

    const [notifications, setNotifications] = useState([])

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                addNotification: (notification) => setNotifications(state => [ ...state, notification ]),
                // addNotification: (notification) => console.log(notification),
                removeNotification: (index) => setNotifications(state => {
                    state.splice(index, 1)
                    return state
                })
            }}
        >
            <AccountCreatedInPerson />
            { children }
        </NotificationContext.Provider>
    )

}

export const useNotification = () => useContext(NotificationContext)