import React from 'react'
import './notes.sass'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_ACCOUNT_NOTES, ADD_ACCOUNT_NOTE } from '../../operations/Account'
import Modal, { ModalClose } from '../Modal'
import { useModal } from '../../hooks/modal'
import Form, { Textarea } from '../Form'

export default function Notes({ accountId }) {

    const { modal, setModal } = useModal()
    const { loading, error, data } = useQuery(GET_ACCOUNT_NOTES, { variables: { accountId } })
    const [ addAccountNote ] = useMutation(ADD_ACCOUNT_NOTE, { refetchQueries: [{ query: GET_ACCOUNT_NOTES, variables: { accountId } }] })

    if (loading || error) return null

    const { accountNotes: notes } = data

    return (
        <>
            <button type='button' className='notes' onClick={() => setModal('NOTES')}>{ notes.length > 0 && <div className='notesCountBubble'>{ notes.length }</div> }<i className='material-icons'>notes</i></button>
            {
                modal && modal === 'NOTES' && (
                    <Modal>
                        <ModalClose />
                        <h1>Notes</h1>
                        {
                            notes.map(note => {

                                const profileImage = note.user.profileImage ? <img className='profileImage' src={ `${note.user.profileImage}?w=40` } alt={ `${ note.user.firstName } ${ note.user.lastName }` }/> : <i className='material-icons'>person</i>
                                return (
                                    <div className='noteContainer' key={ note.id }>
                                        { profileImage }
                                        <div className='note'>
                                            <div className='noteUser'>{ note.user.firstName } { note.user.lastName }</div>
                                            { note.note }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <Form
                            onSubmit={({ state: { inputs }, enableSubmit, resetForm }) => {
                                const note = inputs.note.trim()
                                enableSubmit()
                                resetForm()

                                if (note.length > 0) {
                                    addAccountNote({
                                        variables: {
                                            payload: {
                                                accountId,
                                                note
                                            }
                                        }
                                    })
                                }
                            }}
                        >
                            <Textarea label='Note' name='note' submitOnEnter />
                        </Form>
                    </Modal>
                )
            }
        </>
    )
}