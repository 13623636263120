import React, { useState, useEffect } from "react";
import { useForm } from "./Form";
import InputContainer from "./InputContainer";
import Calendar from "react-calendar";
import moment from "moment";
// import currencyjs from "currency.js";

export default function Input({
  label,
  name,
  value = "",
  type = "text",
  inputMode = "text",
  onChange,
  placeholder,
  autoComplete = "on",
  maxLength,
  error,
  regex,
  uppercase,
  currency,
}) {
  const [isFocused, setFocused] = useState(false);

  const [{ inputs }, setInput] = useForm();

  // Init the input
  useEffect(() => {
    if (typeof inputs[name] === "undefined") {
      setInput({ [name]: value });
    }
  }, [inputs, name, setInput, value]);

  const inputId = `f_${name}`;

  return (
    <InputContainer
      label={label}
      inputId={inputId}
      isFocused={isFocused}
      isFilled={inputs[name]}
      error={error}
      onFocus={(e) => setFocused(true)}
      onBlur={(e) => {
        const currentTarget = e.currentTarget;
        setTimeout(() => {
          if (!currentTarget.contains(document.activeElement))
            setFocused(false);
        }, 0);
      }}
    >
      <input
        id={inputId}
        type={type !== "date" ? type : "text"}
        inputMode={inputMode}
        name={name}
        value={
          type === "date" && inputs[name]
            ? moment(inputs[name]).format("YYYY-MM-DD")
            : inputs[name] || ""
        }
        // {...(currency && {
        //   //   placeholder: "0.00",
        //   min: 0,
        //   // max: 0,
        //   step: "0.01",
        //   pattern: "^d+(?:.d{1,2})?$",
        //   required: true,
        // })}
        onChange={(e) => {
          let formattedValue =
            inputMode === "tel"
              ? e.target.value.replace(/[^0-9.]/g, "")
              : e.target.value;
          if (regex) formattedValue = formattedValue.replace(regex, "");
          if (uppercase) formattedValue = formattedValue.toUpperCase();
          //   if (currency) {
          //     formattedValue = formattedValue.replace(/^[0-9]/g, "");
          //   }
          //   if (currency) {
          //     formattedValue = formattedValue.padEnd(2, "0");
          //     formattedValue = currencyjs(formattedValue).value;
          //   }

          setInput({
            [name]: formattedValue,
          });
          if (onChange)
            onChange({
              target: { name: e.target.name, value: e.target.value },
            });
        }}
        // onFocus={ () => setFocused(true) }
        // onBlur={ () => setFocused(false) }
        autoComplete={type === "date" ? "off" : autoComplete}
        maxLength={maxLength}
      />
      {type === "date" && isFocused && (
        <Calendar
          style={{ zIndex: 100 }}
          calendarType="US"
          onChange={(date) => {
            setInput({
              [name]: date,
            });
            setTimeout(() => {
              setFocused(false);
            }, 0);
          }}
          minDetail="decade"
        />
      )}
    </InputContainer>
  );
}
