import gql from "graphql-tag";
// import { REGISTRATION_DATA } from './Registration'

export const GET_PROGRAM_CATEGORIES_TODAY = gql`
  query ProgramCategoriesToday($today: String) {
    programCategories: programCategoriesToday(today: $today) {
      id
      name
    }
  }
`;

export const UPDATE_CAPACITY = gql`
  mutation UpdateCapacity($programDateId: ID!, $capacity: Int!) {
    updateCapacity(programDateId: $programDateId, capacity: $capacity)
  }
`;

export const GET_PROGRAM_TODAY = gql`
  query Program($today: String, $filter: RegistrationsTodayFilter) {
    registrationsToday(today: $today, filter: $filter) {
      id
      person {
        id
        firstName
        lastName
      }
      isCheckedIn
      isCheckedOut
      isOvernightCamp
    }
  }
`;

export const GET_SEASONS = gql`
  query Seasons {
    seasons {
      id
      name
      categories {
        id
        name
        programs {
          id
          name
          price
          allowSnackbar
        }
      }
    }
  }
`;

export const GET_PROGRAMS = gql`
  query Programs {
    programs {
      id
      name
      price
    }
  }
`;

export const GET_PROGRAMS_WITH_DATES = gql`
  query ProgramsWithDates(
    $programFilter: ProgramFilter
    $programDateFilter: ProgramDateFilter
  ) {
    programs(filter: $programFilter) {
      id
      name
      price
      dates(filter: $programDateFilter) {
        id
        startDate
        endDate
        ageMin
        ageMax
        customName
        capacity
        currentCapacity
        price
      }
    }
  }
`;

export const GET_PROGRAM_DATES = gql`
  query ProgramDates($filter: ProgramDateFilter) {
    programDates(filter: $filter) {
      id
      startDate
      endDate
      ageMin
      ageMax
      customName
      capacity
      currentCapacity
      price
    }
  }
`;

export const REGISTER = gql`
  mutation Register($payload: RegisterPayload!) {
    register(payload: $payload) {
      id
    }
  }
`;

export const GET_CURRENT_PROGRAMS = gql`
  query CurrentPrograms(
    $programFilter: ProgramFilter!
    $programDateFilter: ProgramDateFilter
  ) {
    programs: currentPrograms(filter: $programFilter) {
      id
      name
      price
      category {
        id
        name
        season {
          id
          name
        }
      }
      dates(filter: $programDateFilter) {
        id
        startDate
        endDate
        ageMin
        ageMax
        customName
        capacity
        currentCapacity
      }
    }
  }
`;

export const GET_REGISTRATIONS_BY_EVENT_ID = gql`
  query RegistrationsByEventId($id: ID!) {
    registrations: registrationsByEventId(id: $id) {
      id
      registrationDate
      season {
        name
      }
      category {
        name
      }
      program {
        name
      }
      programDate {
        startDate
        endDate
        customName
      }
      person {
        id
        firstName
        lastName
        dob
        gender
      }
      account {
        id
      }
      options {
        optionTitle
        optionKey
        optionValue
        optionPrice
      }
    }
  }
`;

export const GET_REGISTRATION_ACCOMMODATIONS = gql`
  query RegistrationAccommodations($startDate: String, $endDate: String) {
    registrationAccommodations(startDate: $startDate, endDate: $endDate) {
      id

      program {
        id
        name
      }
      person {
        id
        firstName
        lastName
        age
        dob
        gender
      }
      reservationBuilding
      reservationRoom
      friendRequests {
        fullName
      }
    }
  }
`;

export const SAVE_REGISTRATION_ACCOMMODATION = gql`
  mutation SaveRegistrationAccommodation(
    $registrationId: ID!
    $building: String!
    $room: String!
  ) {
    saveRegistrationAccommodation(
      registrationId: $registrationId
      building: $building
      room: $room
    ) {
      id
      reservationBuilding
      reservationRoom
    }
  }
`;

export const GET_SUMMER_CAMP_DASHBOARD = gql`
  query GetSummerCampDashboard {
    getDashboardRegistrations_summerCamp
  }
`;

export const GENERATE_CAMPER_LIST = gql`
  mutation GenerateCamperList($startDate: String!, $endDate: String!) {
    generateCamperList(startDate: $startDate, endDate: $endDate)
  }
`;

export const UPDATE_FRIEND_REQUEST = gql`
  mutation UpdateFriendRequest($registrationId: ID!, $friendName: String!) {
    updateFriendRequest(
      registrationId: $registrationId
      friendName: $friendName
    )
  }
`;

export const SAVE_CABIN_LEADERS = gql`
  mutation SaveCabinLeaders(
    $startDate: String!
    $endDate: String!
    $cabinLeaders: JSON!
  ) {
    saveCabinLeaders(
      startDate: $startDate
      endDate: $endDate
      cabinLeaders: $cabinLeaders
    )
  }
`;

export const GET_CABIN_LEADERS = gql`
  query GetCabinLeaders($startDate: String!, $endDate: String!) {
    getCabinLeaders(startDate: $startDate, endDate: $endDate)
  }
`;
