import React from "react";
import { Router } from "@reach/router";

import Login from "./pages/Login";

import Modal from "./components/Modal";

import Refresh from "./components/Refresh";

import Header from "./components/Header";
import Layout from "./components/Layout";

import Dashboard from "./pages/Dashboard";
import Inbox from "./pages/Inbox";
import Accounts from "./pages/Accounts";
import Account from "./pages/Account";
import { Person, NewPerson } from "./pages/Person";
import Registrations from "./pages/Registrations";
import Register from "./pages/Register";

import Settings from "./pages/Settings";
import Link from "./pages/Link";

import { useRegistration } from "./hooks/registration";
import { useAuthentication } from "./hooks/authentication";
import { useRefresh } from "./hooks/refresh";
import { useModal } from "./hooks/modal";
import Accommodations from "./pages/Accommodations";
import Refunds from "./pages/Refunds";
import Credits from "./pages/Credits";
import OnSite from "./pages/OnSite";
import Exports from "./pages/Exports";
import ProgramDate from "pages/ProgramDate";
import Transactions from "pages/Transactions";

export default function Routes() {
  const { isAuthenticated } = useAuthentication();
  const { registration } = useRegistration();
  const { shouldRefresh } = useRefresh();
  const { modal } = useModal();

  if (!isAuthenticated)
    return (
      <>
        <Refresh />
        <Login />
      </>
    );

  return (
    <div
      className={`container-default ${
        shouldRefresh ? "container--refreshMargin" : ""
      } ${modal ? "container--blur" : ""}`}
    >
      <Refresh />
      <Header />
      <Layout>
        <Router id="router">
          <Dashboard path="/" />
          <Inbox path="/inbox" />
          <Inbox path="/inbox/:uuid" />
          <Accounts path="/accounts" />
          <Registrations path="/registrations" />
          <Registrations path="/registrations/:programId" />
          <Registrations path="/registrations/:programId/:eventId" />
          <ProgramDate path="/program-date/:programId/:dateId" />

          <Account path="account/:accountId" page="root" />
          <Account
            path="account/:accountId/registrations"
            page="registrations"
          />
          <Account path="account/:accountId/transactions" page="transactions" />
          <Account path="account/:accountId/authorized" page="authorized" />

          <Person path="account/:accountId/person/:personId" />
          <NewPerson path="account/:accountId/new-person" />
          <NewPerson path="account/:accountId/edit/:personId" />
          <Register path="account/:accountId/person/:personId/register" />

          <Accommodations path="accommodations" />
          <Exports path="exports" />
          <Refunds path="refunds" />
          <Credits path="credits" />
          <Transactions path="transactions" />
          <OnSite path="onsite" />

          <Settings path="/settings" />

          <Link path="/link" />
        </Router>
      </Layout>
      {registration.isOpen && (
        <Modal>
          <Register />
        </Modal>
      )}
    </div>
  );
}
