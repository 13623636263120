import React, { useState, useEffect } from 'react'
import { useForm } from './Form'
import InputContainer from './InputContainer'

export default function Select({ label, name, value = '', type = 'text', onChange, placeholder, options = [], noBlankOption }) {

    const [isFocused, setFocused] = useState(false)

    const [{ inputs }, setInput] = useForm()

    // Init the input
    useEffect(() => {
        if (typeof inputs[name] === 'undefined') {
            setInput({[name]: value})
        }
    }, [inputs, name, setInput, value])

    const inputId = `f_${name}`

    return (
        <InputContainer label={ label } inputId={ inputId } isFocused={ isFocused } isFilled={ inputs[name] }>
            <select
                id={ inputId }
                type={ type }
                name={ name }
                value={ inputs[name] || '' }
                onChange={e => {
                    setInput({
                        [name]: e.target.value
                    })
                    if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
                }}
                onFocus={ () => setFocused(true) }
                onBlur={ () => setFocused(false) }
            >
                { !noBlankOption && <option value=''></option> }
                {
                    options && options.map((obj, i) => {

                        const option = Array.isArray(obj) ? obj[1] : obj
                        const optionValue = Array.isArray(obj) ? obj[0] : obj

                        return <option key={i} value={ optionValue }>{ option }</option>
                    })
                }
            </select>
        </InputContainer>
    )

}