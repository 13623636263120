import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_ACCOUNT,
  GET_ACCOUNT_TRANSACTIONS,
  GET_ACCOUNT_REGISTRATIONS,
  SET_ACCOUNT_CREDITS_APPLIED,
  SET_ACCOUNT_STATUS,
} from "../../operations/Account";
import { ADD_PAYMENT } from "../../operations/Transaction";
import Card, {
  CardProfile,
  CardTitle,
  CardLine,
  CardHR,
  CardDate,
} from "../../components/Card";
import Format from "../../components/Format";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { LayoutHeader } from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import Grid from "../../components/Grid";
import moment from "moment";
import { SubNav, NavLink } from "../../components/Nav";
import NoData from "../../components/NoData";
import { Aside, AsideDetail } from "../../components/Aside";
import Table, {
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TrDivider,
} from "../../components/Table";
import Modal, {
  ModalClose,
  ModalHeader,
  ModalTitle,
} from "../../components/Modal";
import { useModal } from "../../hooks/modal";
import Form, {
  FormContext,
  Input,
  Select,
  Submit,
} from "../../components/Form";
import Notes from "../../components/Notes";
import Error from "../../components/Error";
import { Link, useLocation } from "@reach/router";
import CovidScreening from "./CovidScreening";
import AuthorizedPickUp from "./AuthorizedPickUp";
import currency from "currency.js";

import logo from "../../assets/logo.svg";
import "./account.scss";
import { intlFormat, parseISO } from "date-fns";
import { CANCEL_REGISTRATION } from "operations/Registration";

export default function Account({ accountId, page }) {
  const { modal, setModal } = useModal();
  const { loading, error, data } = useQuery(GET_ACCOUNT, {
    variables: { id: accountId },
  });
  const [addPayment] = useMutation(ADD_PAYMENT);
  const [setAccountStatus] = useMutation(SET_ACCOUNT_STATUS);
  const [errorMessage, setErrorMessage] = useState();

  if (loading) return "Loading...";
  if (error) return `Error: ${error.message}`;

  const { account } = data;

  if (!account) return "No account";

  return (
    <>
      <LayoutHeader>
        <Breadcrumb title="Account">{account.name}</Breadcrumb>

        <Breadcrumb
          title="Account Balance"
          right
          to={`/account/${account.id}/transactions`}
        >
          <Format type="currency" value={account.accountBalance} color />
        </Breadcrumb>

        <Notes accountId={account.id} />
      </LayoutHeader>

      <div className="personContainer">
        <div className="personProfile">
          <Aside>
            <AsideDetail title="Account Age">
              {moment(account.createdAt).fromNow(true)}
            </AsideDetail>

            <AsideDetail title="Account Revenue">
              <Format type="currency" value={account.revenue} />
            </AsideDetail>

            <AsideDetail title="NPS Rating">
              <Format type="nps" value={account.npsRating} />
            </AsideDetail>

            <AsideDetail title="Account Status">
              <div>{account.isActive ? "ACTIVE" : "INACTIVE"}</div>
              <Button
                className="activateDeactivateAccount"
                onClick={() =>
                  setAccountStatus({
                    variables: { id: account.id, isActive: !account.isActive },
                  })
                }
              >
                {account.isActive ? "Deactivate account" : "Activate account"}
              </Button>
            </AsideDetail>
          </Aside>
        </div>
        <div className="personMain">
          <SubNav>
            <NavLink to={`/account/${account.id}`}>Members</NavLink>
            <NavLink to={`/account/${account.id}/registrations`}>
              Registrations
            </NavLink>
            <NavLink to={`/account/${account.id}/transactions`}>
              Transactions
            </NavLink>
            <NavLink to={`/account/${account.id}/authorized`}>
              Authorized Pickup
            </NavLink>
          </SubNav>

          {page === "authorized" && <AuthorizedPickUp accountId={account.id} />}

          {page === "transactions" && (
            <Transactions
              accountId={account.id}
              creditsApplied={account.creditsApplied}
            />
          )}

          {page === "registrations" && <Registrations accountId={account.id} />}

          {/* Total sales: <Format type='currency' value={ account.totalSales } /> */}
          {page === "root" &&
            account.members?.sort((a) => a.status === 0 ? 1 : -1).map((member, i) => {
              const age =
                member.dob && moment(member.dob).isValid()
                  ? moment().diff(member.dob, "years", false)
                  : member.age || "N/A";

                console.log({member})
              return (
                <Card key={i} indent to={`person/${member.id}`} deleted={Boolean(member.status === 0)}>
                  <CardProfile person={member} />
                  <Grid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr .5fr" }}>
                    <CardTitle title="Name">
                      {member.firstName} {member.lastName}
                    </CardTitle>
                    <CardTitle title="Gender">{member.gender}</CardTitle>
                    <CardTitle title="Age">{age}</CardTitle>
                    <CardTitle title="Date of Birth">
                      {moment(member.dob).isValid()
                        ? moment(member.dob).format("MMM D, YYYY")
                        : "Unknown"}
                    </CardTitle>
                    <Link to={`/account/${account.id}/edit/${member.id}`}>
                      edit
                    </Link>
                  </Grid>
                </Card>
              );
            })}

          {page === "root" && <CovidScreening accountId={account.id} />}
        </div>
      </div>

      {page === "root" && (
        <Button material to="new-person">
          <i className="material-icons">add</i>
        </Button>
      )}

      {page === "transactions" && (
        <Button material onClick={() => setModal("NEW_TRANSACTION")}>
          <i className="material-icons">add</i>
        </Button>
      )}

      {page === "transactions" && modal === "NEW_TRANSACTION" && (
        <Modal>
          <ModalClose />

          <ModalHeader>
            <i className="material-icons">account_balance</i>

            <ModalTitle title="Account Balance">
              <Format type="currency" value={account.accountBalance} color />
            </ModalTitle>
          </ModalHeader>

          <Form
            onSubmit={async ({ state: { inputs }, enableSubmit }) => {
              const payloads = {
                "Credit Card": {
                  paymentMethod: inputs.paymentMethod,
                  cardType: inputs.cardType,
                  lastFour: inputs.lastFour,
                },
                Cheque: {
                  paymentMethod: inputs.paymentMethod,
                  chequeNumber: inputs.chequeNumber,
                },
                "e-Transfer": {
                  paymentMethod: inputs.paymentMethod,
                  confirmationNumber: inputs.confirmationNumber,
                },
                Cash: {
                  paymentMethod: inputs.paymentMethod,
                },
                Scholarship: {
                  paymentMethod: inputs.paymentMethod,
                },
                "Account Credit": {
                  paymentMethod: inputs.paymentMethod,
                },
                "Balance Adjustment": {
                  paymentMethod: inputs.paymentMethod,
                },
              };

              // Object.keys(payloads[inputs.paymentMethod]).map(val => payloads[inputs.paymentMethod][val])

              if (
                !Object.keys(payloads[inputs.paymentMethod]).every(
                  (val) => payloads[inputs.paymentMethod][val].length > 0
                )
              ) {
                enableSubmit();
                return setErrorMessage("All fields are required.");
              }

              const amount = parseFloat(inputs.amount || 0);

              await addPayment({
                variables: {
                  payload: {
                    ...payloads[inputs.paymentMethod],
                    accountId,
                    amount,
                  },
                },
                refetchQueries: [
                  { query: GET_ACCOUNT, variables: { id: accountId } },
                  {
                    query: GET_ACCOUNT_TRANSACTIONS,
                    variables: { id: accountId },
                  },
                ],
              });

              setModal();
            }}
          >
            <FormContext.Consumer>
              {([{ inputs }]) => {
                return (
                  <>
                    {errorMessage && <Error>{errorMessage}</Error>}
                    <Input
                      name="amount"
                      label="Amount"
                      value={Math.abs(account.accountBalance).toFixed(2)}
                    />
                    <Select
                      name="paymentMethod"
                      label="Method of Payment"
                      options={[
                        ["Credit Card", "Credit / Debit"],
                        ["Cheque", "Cheque"],
                        ["e-Transfer", "e-Transfer"],
                        ["Cash", "Cash"],
                        ["Scholarship", "Scholarship"],
                        ["Account Credit", "Account Credit"],
                        ["Balance Adjustment", "Balance Adjustment"],
                      ]}
                    />
                    {inputs.paymentMethod &&
                      inputs.paymentMethod === "Credit Card" && (
                        <>
                          <Select
                            name="cardType"
                            label="Card Type"
                            options={[
                              ["Visa", "Visa"],
                              ["Mastercard", "Mastercard"],
                              ["AMEX", "American Express"],
                              ["Debit", "Debit"],
                            ]}
                          />
                          <Input
                            inputMode="numeric"
                            name="lastFour"
                            label="Last 4 Digits of Card"
                            autoComplete="off"
                            maxLength="4"
                          />
                        </>
                      )}
                    {inputs.paymentMethod &&
                      inputs.paymentMethod === "Cheque" && (
                        <Input
                          inputMode="numeric"
                          name="chequeNumber"
                          label="Cheque Number"
                          autoComplete="off"
                        />
                      )}
                    {inputs.paymentMethod &&
                      inputs.paymentMethod === "e-Transfer" && (
                        <Input
                          name="confirmationNumber"
                          label="Confirmation Number"
                          autoComplete="off"
                        />
                      )}
                    <Submit>
                      Apply <Format type="currency" value={inputs.amount} />
                    </Submit>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        </Modal>
      )}
    </>
  );
}

function Transactions({ accountId, creditsApplied }) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const searchTransactionId = searchParams.get("transactionId");

  const { loading, error, data } = useQuery(GET_ACCOUNT_TRANSACTIONS, {
    variables: { id: accountId },
  });

  const [accountCreditsApplied] = useMutation(SET_ACCOUNT_CREDITS_APPLIED, {
    refetchQueries: [{ query: GET_ACCOUNT, variables: { id: accountId } }],
  });

  const [transactionId, setTransactionId] = useState(
    searchTransactionId || null
  );

  if (loading) return "Loading...";
  if (error) return `Error: ${error.message}`;

  if (data.account.transactions.length < 1)
    return <NoData>No transactions for this account.</NoData>;

  return (
    <>
      {data.account.transactions.map((transaction) => (
        <Card
          key={transaction.id}
          onClick={() =>
            transactionId === transaction.id
              ? setTransactionId()
              : setTransactionId(transaction.id)
          }
        >
          <CardLine
            type={
              currency(transaction.total)
                .subtract(transaction.creditUsed || 0)
                .subtract(
                  transaction.payments.reduce(
                    (total, payment) =>
                      currency(total).add(payment.amount).value,
                    0
                  )
                ).value > 0
                ? "error"
                : "success"
            }
          />
          <Grid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}>
            <CardTitle title="Invoice #">
              {Number(transaction.id) + 10000}
            </CardTitle>
            <CardTitle title="Date">
              <Format type="short_date" value={transaction.transactionDate} />
            </CardTitle>
            <CardTitle title="Charged" right>
              <Format type="currency" value={transaction.total} />
            </CardTitle>
            <CardTitle title="Credited" right>
              <Format
                type="currency"
                value={currency(transaction.creditUsed).add(
                  transaction.payments.reduce(
                    (total, payment) =>
                      currency(total).add(
                        payment.paymentType === "Account Credit"
                          ? payment.amount
                          : 0
                      ).value,
                    0
                  )
                )}
              />
            </CardTitle>
            <CardTitle title="Paid" right>
              <Format
                type="currency"
                value={transaction.payments.reduce(
                  (total, payment) =>
                    currency(total).add(
                      payment.paymentType !== "Account Credit"
                        ? payment.amount
                        : 0
                    ).value,
                  0
                )}
              />
            </CardTitle>
            <CardTitle title="Amount Due" right>
              <Format
                type="currency"
                value={
                  currency(transaction.total)
                    .subtract(transaction.creditUsed || 0)
                    .subtract(
                      transaction.payments.reduce(
                        (total, payment) =>
                          currency(total).add(payment.amount).value,
                        0
                      )
                    ).value
                }
                colorInvert
              />
            </CardTitle>
          </Grid>
          {transaction.id === transactionId && (
            <div style={{ position: "relative", minHeight: "200px" }}>
              <CardHR />

              <Transaction transaction={transaction} accountId={accountId} />

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  window.print();
                }}
                unstyled
              >
                <Icon>print</Icon>
              </Button>

              <Modal print>
                <Grid
                  style={{
                    gridTemplateColumns: "1fr 1fr",
                  }}
                  className="receiptHeader"
                >
                  <div className="receiptHeaderCell receiptHeaderCell--left">
                    <img className="logo" src={logo} alt="Teen Ranch Logo" />

                    <p>+1 519-941-4501</p>
                    <p>bookkeeper@teenranch.com</p>
                  </div>
                  <div className="receiptHeaderCell receiptHeaderCell--right">
                    <ReceiptInfo transaction={transaction} />
                  </div>
                </Grid>

                <Transaction transaction={transaction} />
              </Modal>
            </div>
          )}
        </Card>
      ))}

      {creditsApplied ? (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          All credits have been applied
        </div>
      ) : (
        <Button
          onClick={() =>
            accountCreditsApplied({ variables: { id: accountId } })
          }
        >
          All account credits applied
        </Button>
      )}
    </>
  );
}

function Transaction({ accountId, transaction }) {
  // console.log(transaction)
  const { modal, setModal } = useModal();

  const [registrationId, setRegistrationId] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [cancelRegistration] = useMutation(CANCEL_REGISTRATION);

  const registration = (
    registrationId
      ? transaction.registrations.filter(
          (registration) => registration.id === registrationId
        )
      : [{}]
  )[0];

  return (
    <>
      <Table
        style={{
          gridTemplateColumns: `
                minmax(150px, 2fr)
                minmax(120px, 0.5fr)
                minmax(50px, 0.3fr)
                minmax(120px, 0.5fr)
            `,
        }}
      >
        <Thead>
          <Tr>
            <Th>Description</Th>
            {/* <Th>Date</Th> */}
            <Th right>Price</Th>
            <Th right>Qty.</Th>
            <Th right>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transaction.registrations.map((registration) => (
            <React.Fragment key={registration.id}>
              <Tr>
                <Td>
                  <div
                    className={`${
                      registration.isCancelled ? "registrationIsCancelled" : ""
                    }`}
                  >
                    {registration.person.firstName}{" "}
                    {registration.person.lastName}: {registration.program.name}{" "}
                    {registration.programDate.customName &&
                      ` - ${registration.programDate.customName}`}
                  </div>
                  {registration.isCancelled ? (
                    <>
                      <div>{registration.notes}</div>
                      <div className="registrationIsCancelled">CANCELLED</div>
                    </>
                  ) : (
                    <strong>
                      <Format
                        type="short_date"
                        value={registration.programDate.startDate}
                      />{" "}
                      -{" "}
                      <Format
                        type="short_date"
                        value={registration.programDate.endDate}
                      />
                    </strong>
                  )}
                  {!registration.isCancelled && (
                    <div>
                      <button
                        type="button"
                        className="cancelRegistrationButton"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRegistrationId(registration.id);
                          setModal("CANCEL_REGISTRATION");
                        }}
                      >
                        Cancel Registration
                      </button>
                    </div>
                  )}
                </Td>
                <Td right>
                  <Format type="currency" value={registration.subtotal} />
                </Td>
                <Td right>1</Td>
                <Td right last>
                  <Format type="currency" value={registration.subtotal} />
                </Td>
                {registration.discountInfo && (
                  <>
                    <Td right colSpan="3">
                      {registration.discountInfo.description}:
                    </Td>
                    <Td right rightPad>
                      -<Format type="currency" value={registration.discount} />
                    </Td>
                  </>
                )}
                {registration.snackbar ? (
                  <>
                    <Td right colSpan="3">
                      Snackbar Deposit:
                    </Td>
                    <Td right rightPad>
                      <Format type="currency" value={registration.snackbar} />
                    </Td>
                  </>
                ) : null}
                {registration.options &&
                  registration.options.map((option) => (
                    <React.Fragment key={option.optionKey}>
                      <Td right colSpan="3">
                        {option.optionTitle.replace(":", "")}:{" "}
                        {option.optionValue}
                      </Td>
                      <Td right rightPad>
                        <Format type="currency" value={option.optionPrice} />
                      </Td>
                    </React.Fragment>
                  ))}
              </Tr>
              <TrDivider colSpan="4" />
            </React.Fragment>
          ))}
        </Tbody>

        <Tfoot>
          <Tr>
            <Td colSpan="3">
              <strong>Subtotal:</strong>
            </Td>
            <Td>
              <Format
                type="currency"
                value={transaction.subtotal + transaction.programOptions}
              />
            </Td>
          </Tr>
          {transaction.discount ? (
            <Tr>
              <Td colSpan="3">Discount:</Td>
              <Td>
                -<Format type="currency" value={transaction.discount} />
              </Td>
            </Tr>
          ) : null}
          <Tr>
            <Td colSpan="3">
              {transaction.taxAbbr} {transaction.taxMulti * 100}%:
            </Td>
            <Td>
              <Format type="currency" value={transaction.tax} />
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="3">Snackbar Deposit:</Td>
            <Td>
              <Format
                type="currency"
                value={transaction.snackbarDeposit || 0}
              />
            </Td>
          </Tr>
          <Tr>
            <Td colSpan="3" borderTop>
              <strong>Total:</strong>
            </Td>
            <Td borderTop>
              <Format type="currency" value={transaction.total} />
            </Td>
          </Tr>
          {transaction.payments.length > 0 &&
            transaction.payments.map((payment) => {
              let paymentString = "";

              if (payment.amount < 0) {
                paymentString = "Refunded ";

                switch (payment.paymentType) {
                  case "Credit Card":
                    paymentString += `to ${
                      payment.paymentSubType
                    } (${payment.creditMask
                      .substr(-4)
                      .split("")
                      .reverse()
                      .join("")})`;
                    break;
                  case "Cheque":
                    paymentString += `by Cheque (${payment.chequeNumber})`;
                    break;
                  case "e-Transfer":
                    paymentString += `by e-Transfer (${payment.confirmationNumber})`;
                    break;
                  case "Cash":
                    paymentString += `with Cash`;
                    break;
                  case "Scholarship":
                    paymentString = `Scholarship Applied`;
                    break;
                  case "Account Credit":
                    paymentString = `Account Credit Applied`;
                    break;
                  case "Balance Adjustment":
                    paymentString = `Balance Adjustment`;
                    break;
                  default:
                    break;
                }
              } else {
                paymentString = "Paid ";

                switch (payment.paymentType) {
                  case "Credit Card":
                    paymentString += `by ${
                      payment.paymentSubType
                    } (${payment.creditMask
                      .substr(-4)
                      .split("")
                      .reverse()
                      .join("")})`;
                    break;
                  case "Cheque":
                    paymentString += `by Cheque (${payment.chequeNumber})`;
                    break;
                  case "e-Transfer":
                    paymentString += `by e-Transfer (${payment.confirmationNumber})`;
                    break;
                  case "Cash":
                    paymentString += `with Cash`;
                    break;
                  case "Scholarship":
                    paymentString = `Scholarship Applied`;
                    break;
                  case "Account Credit":
                    paymentString = `Account Credit Applied`;
                    break;
                  case "Balance Adjustment":
                    paymentString = `Balance Adjustment`;
                    break;
                  default:
                    break;
                }
              }

              return (
                <Tr key={payment.id}>
                  <Td colSpan="3">
                    {paymentString} on{" "}
                    <Format type="short_date" value={payment.paymentDate} />:
                  </Td>
                  <Td>
                    <Format type="currency" value={payment.amount} />
                  </Td>
                </Tr>
              );
            })}
          <Tr>
            <Td colSpan="3" borderTop>
              <strong>Amount Due:</strong>
            </Td>
            <Td borderTop>
              <strong>
                <Format
                  type="currency"
                  value={
                    transaction.total -
                    transaction.payments.reduce(
                      (total, payment) => total + payment.amount,
                      0
                    )
                  }
                />
              </strong>
            </Td>
          </Tr>
        </Tfoot>
      </Table>

      {registrationId && modal === "CANCEL_REGISTRATION" && (
        <Modal>
          <ModalClose />
          <ModalHeader>
            <i className="material-icons">person_remove</i>

            <ModalTitle title="Cancel Registration">
              {registration.person.firstName} {registration.person.lastName}:{" "}
              {registration.program.name}
              <div>
                <strong>
                  {intlFormat(parseISO(registration.programDate.startDate), {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  -{" "}
                  {intlFormat(parseISO(registration.programDate.endDate), {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </strong>
              </div>
            </ModalTitle>
          </ModalHeader>
          {/* <pre>{JSON.stringify(registration, null, 2)}</pre> */}
          <Form
            onSubmit={async ({ state: { inputs }, enableSubmit }) => {
              // Object.keys(payloads[inputs.paymentMethod]).map(val => payloads[inputs.paymentMethod][val])

              if (isNaN(Number(inputs.adminFeeAmount))) {
                enableSubmit();
                return setErrorMessage(
                  "Please enter an admin fee amount formatted like: 0.00"
                );
              }

              const adminFeeAmount = currency(inputs.adminFeeAmount).value;

              await cancelRegistration({
                variables: {
                  payload: {
                    registrationId,
                    adminFeeAmount,
                  },
                },
                refetchQueries: [
                  { query: GET_ACCOUNT, variables: { id: accountId } },
                  {
                    query: GET_ACCOUNT_TRANSACTIONS,
                    variables: { id: accountId },
                  },
                ],
              });

              setModal();
            }}
          >
            <FormContext.Consumer>
              {([{ inputs }]) => {
                return (
                  <>
                    {errorMessage && <Error>{errorMessage}</Error>}
                    <Input name="adminFeeAmount" label="Admin Fee" currency />

                    <Submit>
                      Cancel registration and charge{" "}
                      <Format type="currency" value={inputs.adminFeeAmount} />
                    </Submit>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        </Modal>
      )}
    </>
  );
}

function ReceiptInfo({ transaction }) {
  const printType = transaction.payments.length > 0 ? "Receipt" : "Invoice";
  const payment =
    printType === "Receipt"
      ? transaction.payments[transaction.payments.length - 1]
      : {};

  let paymentMethod = "";
  switch (payment.paymentType) {
    case "Credit Card":
      paymentMethod = (
        <>
          <strong>{payment.paymentSubType}</strong> -{" "}
          {payment.creditMask.substr(-4)}
        </>
      );
      break;
    case "Cheque":
      paymentMethod = (
        <>
          <strong>Cheque</strong> - {payment.chequeNumber}
        </>
      );
      break;
    case "Cash":
      paymentMethod = <strong>Cash</strong>;
      break;
    case "e-Transfer":
      paymentMethod = <strong>e-Transfer</strong>;
      break;
    case "Scholarship":
      paymentMethod = `Scholarship`;
      break;
    case "Account Credit":
      paymentMethod = `Account Credit`;
      break;
    default:
      break;
  }

  return (
    <>
      <h1>{printType}</h1>

      <table>
        <tbody>
          {printType === "Receipt" && (
            <tr>
              <th>Receipt #</th>
              <td>{Number(payment.id) + 10000}</td>
            </tr>
          )}
          <tr>
            <th>Invoice #</th>
            <td>{Number(transaction.id) + 10000}</td>
          </tr>
          {printType === "Receipt" && (
            <>
              <tr>
                <th>Date Paid</th>
                <td>
                  <Format type="short_date" value={payment.paymentDate} />
                </td>
              </tr>
              <tr>
                <th>Payment Method</th>
                <td>{paymentMethod}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  );
}

function Registrations({ accountId }) {
  const { loading, error, data } = useQuery(GET_ACCOUNT_REGISTRATIONS, {
    variables: { id: accountId },
  });

  if (loading) return "Loading...";
  if (error) return `Error: ${error.message}`;

  const { account } = data;

  const { registrations } = account;

  if (registrations.length < 1)
    return <NoData>No registrations for this account.</NoData>;

  const now = moment().unix();
  const nowYear = moment().year();

  return registrations.map((registration, key) => {
    let tense = "future";
    if (moment(registration.programDate.startDate).unix() < now) tense = "past";
    if (
      moment(registration.programDate.startDate).unix() <= now &&
      moment(registration.programDate.endDate).unix() >= now
    )
      tense = "present";

    let yearTense = tense;
    if (
      yearTense === "future" &&
      moment(registration.programDate.startDate).year() === nowYear
    )
      yearTense = "present";

    return (
      <div key={registration.id}>
        <Card indent>
          {/* <Grid grid='1-1-1'> */}
          <CardDate tense={tense}>
            {registration.programDate.startDate}
          </CardDate>
          <Grid
            style={{
              gridTemplateColumns: `
                                    minmax(150px, 0.5fr)
                                    minmax(300px, 2fr)
                                `,
            }}
          >
            <CardTitle
              title="Person"
              to={`/account/${accountId}/person/${registration.person.id}`}
            >
              {registration.person.firstName} {registration.person.lastName}
            </CardTitle>
            <CardTitle title="Program">
              {registration.program.name}{" "}
              {registration.programDate.customName &&
                `(${registration.programDate.customName})`}{" "}
              {registration.notes && `${registration.notes}`}
            </CardTitle>
          </Grid>
          {/* </Grid> */}
        </Card>
        <div className={`timeline timeline--${tense}`} />
        {!registrations[key + 1] ||
        registration.programDate.startDate.substr(0, 4) !==
          registrations[key + 1].programDate.startDate.substr(0, 4) ? (
          <>
            <div className={`timelineYear timelineYear--${yearTense}`}>
              <div className={`year`}>
                {registration.programDate.startDate.substr(0, 4)}
              </div>
            </div>
            {registrations[key + 1] && (
              <div className={`timeline timeline--${yearTense}`} />
            )}
          </>
        ) : null}
      </div>
    );
  });
}
