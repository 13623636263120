import React from 'react'
import './aside.css'

export function Aside({ children }) {
    return <div className='aside'>{ children }</div>
}

export function AsideDetail({ title, children }) {
    return (
        <div className='asideDetail'>
            <div className='asideDetail__title'>{ title }</div>
            <div className='asideDetail__value'>{ children }</div>
        </div>
    )
}