import React, { createContext, useContext, useState } from 'react'

const AccountContext = createContext()

export default function Account({ children }) {

    const [account, setAccount] = useState({})
    const [person, setPerson] = useState({})

    

    return (
        <AccountContext.Provider
            value={{
                account,
                person,
                setAccount: (account) => setAccount(account),
                setPerson: (person) => setPerson(person)
            }}
        >
            { children }
        </AccountContext.Provider>
    )

}

export const useAccount = () => useContext(AccountContext)