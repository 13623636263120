import React from 'react'

import './grid.css'

export default function Grid({ grid, style, className, children }) {

    return (
        <div className={`grid ${ grid ? `grid-${ grid }` : '' } ${ className }`} style={ style }>
            { children }
        </div>
    )

}