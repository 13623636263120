import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Form, { Fieldset, Input, Select, Submit } from '../../components/Form'
import Grid from '../../components/Grid'
import { GET_PERSON, GET_ACCOUNT } from '../../operations/Account'
import { UPDATE_PERSON_BY_ID, CREATE_PERSON } from '../../operations/Person';
import { navigate } from '@reach/router'

export default function Edit({ accountId, personId }) {

    const [ updatePerson ] = useMutation(UPDATE_PERSON_BY_ID)
    const [ createPerson ] = useMutation(CREATE_PERSON)

    const { loading, error, data } = useQuery(GET_PERSON, { variables: { id: personId }, skip: !personId })

    if (loading) return 'Loading...'
    if (error) return `Error: ${ error.message }`

    const person = data && data.person ? data.person : null

    return (
        <Form

                init={{
                    ...(person && {
                        firstName: person.firstName,
                        lastName: person.lastName,
                        address: person.address,
                        address2: person.address2,
                        city: person.city,
                        province: person.province,
                        postal: person.postal,
                        country: person.country,
                        dob: person.dob,
                        gender: person.gender,
                        number: person.number,
                        ooc: person.ooc,
                        concerns: person.concerns,
                        needs: person.needs,
                        email: person.email,
                        phone: person.phone
                    })
                }}
                
                onSubmit={({ state: { inputs }, enableSubmit }) => {

                    if (person) {
                    
                        updatePerson({
                            variables: {
                                payload: {
                                    id: person.id,
                                    data: inputs
                                }
                            },
                            refetchQueries: [{ query: GET_PERSON, variables: { id: person.id } }]
                        })

                        enableSubmit()

                    }

                    if (!person) {

                        createPerson({
                            variables: {
                                payload: {
                                    id: accountId,
                                    data: inputs
                                }
                            },
                            refetchQueries: [{ query: GET_ACCOUNT, variables: { id: accountId }  }]
                        })

                        // TODO: this should redirect to person's page

                    }

                    navigate(`/account/${ accountId }`)

                }}
            >
                <Grid grid='1-1-1'>
                    
                    <Fieldset legend='Personal'>
                        <Input name='firstName' label='First Name' />
                        <Input name='lastName' label='Last Name' />
                        <Input name='dob' label='Date of Birth' />
                        <Select name='gender' label='Gender' options={['Male', 'Female']} />
                        <Input name='email' label='Email' />
                        <Input name='phone' label='Phone' />

                    </Fieldset>

                    <Fieldset legend='Address'>
                        <Input name='address' label='Address' />
                        <Input name='address2' label='Address Line 2' />
                        <Input name='city' label='City' />
                        <Input name='province' label='Province / State' />
                        <Input name='postal' label='Postal / Zip Code' />
                        <Input name='country' label='Country' />
                    </Fieldset>

                    <Fieldset legend='Medical'>
                        <Input name='number' label='Health Card #' />
                        <Input name='ooc' label='Out of Country Insurance' />
                        <Input name='concerns' label='Health Concerns / Allergies' />
                        <Input name='needs' label='Support Needs' />
                    </Fieldset>
                    
                </Grid>
                <Submit>Save</Submit>
            </Form>
    )

}