import React from 'react'

import './nodata.css'

export default function  NoData({ children }) {
    return (
        <div className='noData'>
            { children }
        </div>
    )
}