import React from 'react'

import './table.css'

export default function Table({ children, style }) {
    return <table className='table' style={ style }>{ children }</table>
}

export function Thead({ children }) {
    return <thead className='tableHead'>{ children }</thead>
}

export function Tbody({ children }) {
    return <tbody className='tableBody'>{ children }</tbody>
}

export function Tfoot({ children }) {
    return <tbody className='tableFoot'>{ children }</tbody>
}

export function Tr({ children, header }) {
    return <tr className={`tableRow ${ header ? 'tableRow--header' : '' }`}>{ children }</tr>
}

export function Th({ children, right }) {
    return <th className={`tableColumn tableColumn--header ${ right ? 'alignRight' : '' }`}>{ children }</th>
}

export function Td({ children, right, colSpan, borderTop, last, rightPad }) {
    // console.log(style)

    const styles = {}
    if (colSpan) styles.gridColumn = `span ${ colSpan }`

    return <td className={`tableColumn ${ right ? 'alignRight' : '' } ${ rightPad ? 'right--pad' : '' } ${ borderTop ? 'tableColumn--borderTop' : '' } ${ last ? 'tableColumn--last' : '' }`} colSpan={ colSpan } style={ styles }>{ children }</td>
}

export function TrDivider({ colSpan }) {
    const styles = {}
    if (colSpan) styles.gridColumn = `span ${ colSpan }`
    return <tr className={`tableRowDivider`}><td style={ styles } colSpan={colSpan} /></tr>
}