export const operators = {
    IS: function(a, b) { return a === b },
    IS_NOT: function(a, b) { return a !== b },
    IS_GREATER_THAN: function(a, b) { return a > b },
    IS_GREATER_THAN_OR_EQUAL: function(a, b) { return a >= b },
    IS_LESS_THAN: function(a, b) { return a < b },
    IS_LESS_THAN_OR_EQUAL: function(a, b) { return a <= b },
    CONTAINS: function(string, substring) { return string.toUpperCase().indexOf(substring) !== -1 },
    DOES_NOT_CONTAIN: function(string, substring) { return string.toUpperCase().indexOf(substring) === -1 },
    STARTS_WITH: function(string, substring) { return string.toUpperCase().substring(0, substring) === substring },
    ENDS_WITH: function(string, substring) { return string.toUpperCase().slice(-substring.length) === substring },
}

export const IS = {
    name: 'IS',
    title: 'is'
}

export const IS_NOT = {
    name: 'IS_NOT',
    title: 'is not'
}

export const IS_GREATER_THAN = {
    name: 'IS_GREATER_THAN',
    title: 'is greater than'
}

export const IS_GREATER_THAN_OR_EQUAL = {
    name: 'IS_GREATER_THAN_OR_EQUAL',
    title: 'is greater than or equal to'
}

export const IS_LESS_THAN = {
    name: 'IS_LESS_THAN',
    title: 'is less than'
}

export const IS_LESS_THAN_OR_EQUAL = {
    name: 'IS_LESS_THAN_OR_EQUAL',
    title: 'is less than or equal to'
}

export const CONTAINS = {
    name: 'CONTAINS',
    title: 'is one of'
}

export const DOES_NOT_CONTAIN = {
    name: 'DOES_NOT_CONTAIN',
    title: 'is not one of'
}

export const STARTS_WITH = {
    name: 'STARTS_WITH',
    value: 'starts with'
}

export const ENDS_WITH = {
    name: 'ENDS_WITH',
    value: 'ends with'
}