import gql from "graphql-tag";
import { REGISTRATION_DATA } from "./Registration";

export const REVENUE = gql`
  query Revenue($current: RevenueFilter, $comparison: RevenueFilter) {
    current: revenue(filter: $current) {
      revenue
      date
    }
    comparison: revenue(filter: $comparison) {
      revenue
      date
    }
  }
`;

const PAYMENT_DATA = gql`
  fragment PaymentParts on Payment {
    id
    paymentGateway
    paymentType
    paymentSubType
    nameOnCard
    creditMask
    expiryDate
    chequeNumber
    confirmationNumber
    amount
    paymentDate
  }
`;

export const TRANSACTION_DATA = gql`
  fragment TransactionParts on Transaction {
    id
    transactionType
    subtotal
    discount
    programOptions
    tax
    taxAbbr
    taxMulti
    snackbarDeposit
    creditUsed
    total
    transactionDate
    balanceDecision
    refundAmount
    remainderAmount
    payments {
      ...PaymentParts
    }
    registrations {
      ...RegistrationParts
    }
  }
  ${PAYMENT_DATA}
  ${REGISTRATION_DATA}
`;

export const GET_BALANCE_DECISIONS = gql`
  query BalanceDecisions {
    balanceDecisions {
      id
      account {
        id
      }
      balanceDecision
      refundAmount
      remainderAmount
    }
  }
`;

export const GET_ACCOUNT_CREDITS = gql`
  query GetAccountCredits($startDate: String!, $endDate: String!) {
    accountCredits(startDate: $startDate, endDate: $endDate) {
      id
      userId
      name
      accountBalance
      creditsApplied
      amountDueWeek
      testToken
      paymentRemindersSent(startDate: $startDate, endDate: $endDate)
      # covidFormsSent(startDate: $startDate, endDate: $endDate)
      # covidFormCompleted
      stripeCustomerId
      snackbarAmountLimit
      mobilePhone
    }
  }
`;

export const ADD_PAYMENT = gql`
  mutation AddPayment($payload: PaymentPayload!) {
    addPayment(payload: $payload)
  }
`;

export const GET_RECENT_TRANSACTIONS = gql`
  query GetRecentTransactions($filter: RecentTransactionsFilter) {
    recentTransactions(filter: $filter) {
      id

      transactionType
      subtotal
      discount
      programOptions
      tax
      taxAbbr
      taxMulti
      snackbarDeposit
      creditUsed
      total
      transactionDate
      balanceDecision
      refundAmount
      remainderAmount
      account {
        id
      }
    }
  }
`;
