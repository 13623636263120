import React from 'react'

export default function InputContainer({ children, label, isFocused, onFocus, onBlur, isFilled, inputId, type, error }) {

    return (
        <div className={ `inputContainer ${isFilled ? 'input--filled' : ''} ${isFocused ? 'input--focused' : ''} ${ type === 'search' ? 'inputContainer--search' : '' }` }
            onFocus={(e) => onFocus && onFocus(e)}
            onBlur={(e) => onBlur && onBlur(e)}
        >
                { children }
                    
                <div className='inputBorderContainer'>
                    <div className='inputBorder--left' />
                    <div className='inputBorder--middle'>
                        <label htmlFor={ inputId }>{ label }</label>
                    </div>
                    <div className='inputBorder--right' />
                    { error && <div className='errorMessage'>{ error }</div> }
                </div>
        </div>
    )

}