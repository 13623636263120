import React from 'react'
import { useRefresh } from '../../hooks/refresh'
import './refresh.css'

export default function Refresh() {

    const { shouldRefresh } = useRefresh()

    if (shouldRefresh) return (
        <div className='refreshContainer'>
            New features are available. Refresh the page to see what's new: <button type='button' onClick={() => window.location.reload(true)}>Click to Refresh</button>
        </div>
    )

    return null
}