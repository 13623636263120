import React from "react";
import "./layout.css";

export default function Layout({ children }) {
  return <main className="w-full">{children}</main>;
}

export function LayoutHeader({ children }) {
  return (
    <div className="layoutHeaderContainer">
      <div className="layoutHeader">{children}</div>
    </div>
  );
}
