import React from 'react'
import PropTypes from 'prop-types'

const EXCEPTIONED_INITIALS = [
    'BS',
    'VD',
    'FU',
    'BJ',
    'MF'
]

export function ProfilePicture({ person }) {

    const firstInitial = person.firstName.substr(0, 1)
    const lastInitial = person.lastName.substr(0, 1)

    const initials = EXCEPTIONED_INITIALS.find((exception) => exception === firstInitial + lastInitial) ? firstInitial : firstInitial + lastInitial

    return (
        <div className={`personProfilePicture ${ person.gender ? `gender--${ person.gender.toLowerCase() }` : '' }`}>
            { initials }
        </div>
    )
}

ProfilePicture.propTypes = {
    person: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired
    })
}