import React from 'react'
import moment from 'moment'
import './format.css'

export default function Format({ value, type, color, colorInvert }) {

    if (type === 'currency') {
        return <span className={`${ color || colorInvert ? 'currencyHighlight' : '' } ${ (value < 0 && color) || (value > 0 && colorInvert) ? 'currencyHighlight--negative' : '' }`}>{ new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value).replace(/US/g, "") }</span>
    }

    if (type === 'number') {
        return new Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(value)
    }

    if (type === 'nps') {
        
        if (!value) return 'N/A'

        let customer = 'promoter'
        if (value <= 8) customer = 'passive'
        if (value <= 6) customer = 'detractor'
        
        return <span className={`npsRating npsRating--${ customer }`}>{ value }</span>

    }

    if (type === 'short_date') {

        return moment(value).format('MMM D, YYYY')

    }

    return null

}