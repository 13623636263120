import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_CURRENT_PROGRAMS,
  GET_REGISTRATIONS_BY_EVENT_ID,
} from "../../operations/Program";
import moment from "moment";
import Card, {
  CardTitle,
  CardCapacity,
  CardProfile,
} from "../../components/Card";
// import Capacity from '../../components/Capacity'
import Grid from "../../components/Grid";
import Format from "../../components/Format";
import { LayoutHeader } from "../../components/Layout";
import Calendar from "react-calendar";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Scroll from "../../components/Scroll";
import { useDateRange } from "../../hooks/dateRange";
import NoData from "../../components/NoData";

function Registrations({ programId, eventId }) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const { startDate, setStartDate, endDate, setEndDate } = useDateRange();

  const { loading, error, data } = useQuery(GET_CURRENT_PROGRAMS, {
    variables: {
      programFilter: {
        startDate: { lt: endDate.format("YYYY-MM-DD") },
        endDate: { gt: startDate.format("YYYY-MM-DD") },
      },
      programDateFilter: {
        startDate: { lt: endDate.format("YYYY-MM-DD") },
        endDate: { gt: startDate.format("YYYY-MM-DD") },
      },
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const program = programId
    ? data.programs.find((program) => program.id === programId)
    : false;
  const event = eventId
    ? program.dates.find((event) => event.id === eventId)
    : false;

  return (
    <>
      <LayoutHeader>
        <Breadcrumb
          title="Date Range"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div onClick={() => setCalendarOpen(!calendarOpen)}>
            {startDate.format("MMM D, YYYY")} - {endDate.format("MMM D, YYYY")}
          </div>
          {calendarOpen && (
            <div style={{ position: "absolute", background: "#fff" }}>
              <Calendar
                selectRange
                calendarType="US"
                value={[new Date(startDate), new Date(endDate)]}
                onChange={([startDate, endDate]) => {
                  setStartDate(moment(startDate));
                  setEndDate(moment(endDate));
                  setCalendarOpen(false);
                }}
              />
            </div>
          )}
        </Breadcrumb>
        {program && (
          <Breadcrumb title="Program" to={`/registrations/${program.id}`}>
            {program.name}
          </Breadcrumb>
        )}
        {event && (
          <Breadcrumb title="Event">
            {event.customName
              ? `${event.customName}: ${moment(event.startDate).format(
                  "MMM D, YYYY"
                )} - ${moment(event.endDate).format("MMM D, YYYY")}`
              : `${moment(event.startDate).format("MMM D, YYYY")} - ${moment(
                  event.endDate
                ).format("MMM D, YYYY")}`}
          </Breadcrumb>
        )}
      </LayoutHeader>

      <Scroll>
        <Grid grid="1-1">
          <h1>Registrations</h1>
          <div style={{ textAlign: "right" }}>
            <Button unstyled onClick={() => setShowFilters(!showFilters)}>
              <Icon>{showFilters ? "close" : "filter_list"}</Icon>
            </Button>
          </div>
        </Grid>

        {showFilters && (
          <div
            style={{
              background: "rgba(0,0,0,0.05)",
              borderRadius: "5px",
              padding: "2rem",
              borderTop: "1px solid rgba(0,0,0,0.075)",
              borderLeft: "1px solid rgba(0,0,0,0.05)",
              borderRight: "1px solid rgba(0,0,0,0.05)",
            }}
          >
            <h2 style={{ margin: "0px" }}>Filters (coming soon)</h2>
          </div>
        )}

        {event && <Event event={event} />}

        {program &&
          !event &&
          program.dates.map(
            ({
              id,
              startDate,
              endDate,
              ageMin,
              ageMax,
              capacity,
              currentCapacity,
            }) => (
              <Card key={id} to={id}>
                <Grid style={{ gridTemplateColumns: "auto auto auto" }}>
                  <CardTitle title="Date">
                    {moment(startDate).format("MMM D, YYYY")} -{" "}
                    {moment(endDate).format("MMM D, YYYY")}
                  </CardTitle>
                  <CardTitle title="Ages">
                    {ageMin} - {ageMax}
                  </CardTitle>
                  <CardTitle title="Capacity">
                    {currentCapacity} / {capacity}
                  </CardTitle>
                </Grid>
                <CardCapacity
                  currentCapacity={currentCapacity}
                  capacity={capacity}
                />
              </Card>
            )
          )}

        {!program &&
          (data.programs.length > 0 ? (
            data.programs.map(({ id, name, price, dates }) => (
              <Card key={name} to={id}>
                <Grid grid="1-1-1">
                  <CardTitle title="Program">{name}</CardTitle>
                  <CardTitle title="Price">
                    <Format type="currency" value={price} />
                  </CardTitle>
                  <CardTitle
                    title="Capacity"
                    style={{ textAlign: "right", position: "relative" }}
                  >
                    <CapacityCalculator dates={dates} />
                  </CardTitle>
                  <CapacityCalculator dates={dates} type="graph" />
                </Grid>
              </Card>
            ))
          ) : (
            <NoData>
              No programs are running from {startDate.format("MMMM D, YYYY")} to{" "}
              {endDate.format("MMMM D, YYYY")}
            </NoData>
          ))}
      </Scroll>
    </>
  );
}

function CapacityCalculator({ dates, type }) {
  let capacity = 0;
  let currentCapacity = 0;

  dates.map((date) => {
    capacity += date.capacity;
    currentCapacity += date.currentCapacity;
    return true;
  });

  if (type === "graph")
    return (
      <CardCapacity currentCapacity={currentCapacity} capacity={capacity} />
    );

  return (
    <>
      {currentCapacity} / {capacity}
    </>
  );
}

function Event({ event }) {
  const { error, loading, data } = useQuery(GET_REGISTRATIONS_BY_EVENT_ID, {
    variables: { id: event.id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if (data.registrations.length > 0) {
    return (
      <>
        <h2>
          Capacity: {event.currentCapacity}/{event.capacity}
        </h2>
        {data.registrations.map(
          ({ id, registrationDate, person, account, options }) => {
            return (
              <Card key={person.id} indent>
                <CardProfile person={person} />
                <Grid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}>
                  <CardTitle
                    title="Name"
                    to={`/account/${account.id}/person/${person.id}`}
                  >
                    {person.firstName} {person.lastName}
                    {options && (
                      <div style={{ display: "flex", gap: "1rem" }}>
                        {options.map((option, i) => {
                          return (
                            <Pill>
                              {option.optionTitle} {option.optionValue}
                            </Pill>
                          );
                        })}
                      </div>
                    )}
                  </CardTitle>
                  <CardTitle title="Gender">{person.gender}</CardTitle>
                  <CardTitle title="Age">
                    {person.dob && moment(person.dob).isValid()
                      ? moment().diff(person.dob, "years", false)
                      : person.age || "N/A"}
                  </CardTitle>
                  <CardTitle title="Date of Birth">
                    {moment(person.dob).isValid()
                      ? moment(person.dob).format("MMM D, YYYY")
                      : "Unknown"}
                  </CardTitle>
                  <CardTitle title="Registration Date">
                    {moment(registrationDate).isValid()
                      ? moment(registrationDate).format("MMM D, YYYY")
                      : "Unknown"}
                  </CardTitle>
                </Grid>
              </Card>
            );
          }
        )}
      </>
    );
  }

  return <NoData>No one is registered for this event.</NoData>;
}

export default Registrations;

function Pill({ children }) {
  return (
    <div
      style={{
        fontSize: "9pt",
        padding: ".1rem 1rem",
        borderRadius: "1000px",
        background: "#ccc",
      }}
    >
      {children}
    </div>
  );
}
