export const DATE = {
    name: 'DATE',
    title: 'Current Date'
}

export const CURRENT_YEAR_SEASON = {
    name: 'CURRENT_YEAR_SEASON',
    title: 'in current year and season'
}

export const REGISTERED = {
    name: 'REGISTERED',
    title: 'registered'
}

export const SEASON = {
    name: 'SEASON',
    title: 'Season'
}

export const REGISTRANT = {
    name: 'REGISTRANT',
    title: 'Registrant'
}

export const parameterTypes = {

    REGISTERED: {

        CURRENT_YEAR_SEASON: () => {

            /**
             *  Return true/false if registered in current year season
             */

        }

    },


}