import React from 'react'

import './scroll.css'

export default function Scroll({ children }) {
    return (
        <div className='scrollContainer--none'>
            { children }
        </div>
    )
}