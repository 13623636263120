import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_PAGINATED_ACCOUNTS, CREATE_ACCOUNT, CHECK_ACCOUNT_EMAIL } from '../../operations/Account'
import Card, { CardTitle } from '../../components/Card'
import { LayoutHeader } from '../../components/Layout'
import Form, { FormContext, Search, Input, Select, Submit } from '../../components/Form'
import Scroll from '../../components/Scroll'
import NoData from '../../components/NoData'
import Grid from '../../components/Grid'
import moment from 'moment'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Modal, { ModalClose } from '../../components/Modal'
import { useModal } from '../../hooks/modal'
import validator from 'validator'
import { navigate } from '@reach/router'

const LIMIT = 250

export default function Accounts() {

    const { modal, setModal } = useModal() 
    const [ showFilters, setShowFilters ] = useState(false)
    const [ filters, setFilters ] = useState({})

    const [ref, inView] = useInView({
        threshold: 0.1,
        rootMargin: '0px'
    })

    const [searchTerm, setSearchTerm] = useState()

    const { loading, error, data, fetchMore } = useQuery(GET_PAGINATED_ACCOUNTS, {
        variables: {
            paginate: {
                first: LIMIT //filters ? 25 : LIMIT
            },
            term: searchTerm,
            filters
        }
    })

    const { accounts } = data || {}

    useEffect(() => {
        if (inView) {
            fetchMore({
                variables: {
                    paginate: {
                        first: LIMIT,
                        after: accounts.cursors.after
                    },
                    term: searchTerm
                },

                updateQuery: (prevResult, { fetchMoreResult }) => {
                    
                    const newEdges = fetchMoreResult.accounts.edges
                    const cursors = fetchMoreResult.accounts.cursors

                    return newEdges.length ? {
                        accounts: {
                            __typename: prevResult.accounts.__typename,
                            edges: [...prevResult.accounts.edges, ...newEdges],
                            cursors
                        }
                    } : prevResult

                }
            })
        }
    }, [inView, fetchMore, accounts, searchTerm])

    if (loading) return 'Loading...'
    if (error) return 'Error...'

    return (
        <>
            <LayoutHeader>
                <div style={{ marginLeft: '2rem', maxWidth: '320px' }}><Form
                    onSubmit={({ state: { inputs }, enableSubmit }) => {
                        setSearchTerm(inputs.search)
                        enableSubmit()
                    }}
                ><Search label='Search by first &amp; last name'/></Form>
                </div>
            </LayoutHeader>
            <Scroll>
                <Grid style={{ gridTemplateColumns: `
                    auto
                    60px
                ` }}>
					
                    <div>
                        <h1>Accounts</h1>
                        {
                            filters && (
                                <>
                                    <strong>Filters: </strong>
                                    { Object.keys(filters).map((filter, i) => <span key={i}>{ filters[filter] }</span>) }
                                </>
                            )
                        }
                    </div>
                    
					<div style={{ textAlign: 'right' }}>
						<Button unstyled onClick={() => setShowFilters(!showFilters)}><Icon>{ showFilters ? 'close' : 'filter_list' }</Icon></Button>
					</div>
				</Grid>

                {
					showFilters && (
						<div style={{
							background: '#fafafa',
							borderRadius: '5px',
							padding: '2rem',
							borderTop: '1px solid rgba(0,0,0,0.05)',
							borderLeft: '1px solid rgba(0,0,0,0.025)',
							borderRight: '1px solid rgba(0,0,0,0.025)'
						}}>
							<h2 style={{ margin: '0px' }}>Filters</h2>

                            <Form
                                onSubmit={({ state: { inputs }, enableSubmit }) => {
                                    setFilters(inputs)
                                    setShowFilters(false)
                                }}
                            >
                                <Select name='accountType' label='Account Type' options={['All', 'Walk-in']} />
                                <Submit>Apply</Submit>
                            </Form>

						</div>
					)
				}

                {

                    accounts.edges.length > 0 ? (
                        accounts.edges.map(({node}) => {

                            const address = node.addresses[0]

                            return <Card to={`/account/${node.id}`} key={ node.id } className={`${ node.isActive ? 'account--active' : 'account--inactive' }`}>
                                <Grid 
                                style={{ 
                                    gridTemplateColumns: `
                                        minmax(150px, 1fr)
                                        minmax(150px, 1fr)
                                        minmax(150px, 1fr)
                                        minmax(150px, 1fr)
                                    `
                                }}
                                >
                                    <CardTitle title='Account'>
                                        { node.name }
                                    </CardTitle>
                                    <CardTitle title='Account Age'>
                                        { moment(node.createdAt).fromNow(true) }
                                    </CardTitle>
                                    <CardTitle title='From'>
                                        { address ? <>{ address.city }, { address.province }</> : 'N/A' }
                                    </CardTitle>
                                    <CardTitle title='Account Status'>
                                        { node.isActive ? 'ACTIVE' : 'INACTIVE' }
                                    </CardTitle>
                                </Grid>
                            </Card>
                        })
                    ) : (
                        <NoData>Sorry, but we couldn't find any accounts matching that search term.</NoData>
                    )
                }
                { accounts.cursors.hasNext && <div ref={ ref }>Loading</div> }
            </Scroll>

            {
                modal === 'NEW_ACCOUNT' && (
                    <Modal>
                        <ModalClose />
                        <NewAccount />
                    </Modal>
                )
            }

            <Button material onClick={() => setModal('NEW_ACCOUNT')}><i className='material-icons'>add</i></Button>
        </>
    )

} 

function NewAccount() {

    const { setModal } = useModal()
    const [ emailStatus, setEmailStatus ] = useState()
    const [ createAccount ] = useMutation(CREATE_ACCOUNT)

    return (
        <Form
            onSubmit={ async ({ state: { inputs } }) => {
                try {
                    const { data } = await createAccount({
                        variables: {
                            payload: {
                                firstName: inputs.firstName,
                                lastName: inputs.lastName,
                                email: inputs.email,
                                phone: inputs.phone,
                            }
                        }
                    })
                    
                    const { createAccount: account } = data

                    setModal()
                    navigate(`/account/${ account.id }`)
                }
                catch (err) {
                    console.log(err)
                }
            }}
        >
            <FormContext.Consumer>
                {(([{ inputs }]) => {
                    return (
                        <>
                            <h1>Account holder</h1>
                            <h2>Must be 18+</h2>
                            <Input name='firstName' label='First Name' />
                            <Input name='lastName' label='Last Name' />
                            <Input name='email' label='Email' error={ emailStatus === 'IN_USE' ? 'Sorry, this email address is in use.' : '' } />
                            <CheckValidEmail email={ inputs.email } onCheck={(isValid) => setEmailStatus(isValid ? 'NOT_IN_USE' : 'IN_USE') } />
                            <Input name='phone' label='Phone' inputMode='tel' />

                            <Submit disabled={ emailStatus !== 'NOT_IN_USE' || !inputs.firstName || !inputs.lastName || !inputs.email || !inputs.phone }>Create Account</Submit>
                        </>
                    )
                })}
            </FormContext.Consumer> 
        </Form>
    )

}

function CheckValidEmail({ email, onCheck }) {

    const isEmail = email && validator.isEmail(email) ? true : false
    
    const { loading, error, data } = useQuery(CHECK_ACCOUNT_EMAIL, { variables: { email }, skip: !isEmail, fetchPolicy: 'network-only' })

    if (!isEmail) return null

    if (loading) return null
    if (error) return `Error: ${error.message}`

    if (onCheck) onCheck(!data.checkAccountEmail)

    return null
    
}