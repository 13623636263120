import { useMutation, useQuery } from "@apollo/react-hooks";
import { useParams } from "@reach/router";
import Form, { FormContext, Input, Submit } from "components/Form";
import { intlFormat, parseISO } from "date-fns";
import { GET_PROGRAMS_WITH_DATES, UPDATE_CAPACITY } from "operations/Program";
import React from "react";

export default function ProgramDate() {
  const { programId, dateId } = useParams();
  const { error, loading, data } = useQuery(GET_PROGRAMS_WITH_DATES, {
    variables: {
      programFilter: { id: { eq: programId } },
      programDateFilter: { id: { eq: dateId } },
    },
  });

  const [updateCapacity] = useMutation(UPDATE_CAPACITY, {
    refetchQueries: [
      {
        query: GET_PROGRAMS_WITH_DATES,
        variables: {
          programFilter: { id: { eq: programId } },
          programDateFilter: { id: { eq: dateId } },
        },
      },
    ],
  });

  if (error) return "Error loading data";
  if (loading) return "Loading...";

  const program = (data && data.programs && data.programs[0]) || {};
  const programDate = program && program.dates ? program.dates[0] : {};

  const isFull = programDate.capacity === programDate.currentCapacity;

  return (
    <div>
      <h1>Program Date</h1>
      <h2 className="tw-flex tw-gap-2">
        <span>{program.name}</span>
        <span>
          (
          {intlFormat(parseISO(programDate.startDate), {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}{" "}
          -{" "}
          {intlFormat(parseISO(programDate.endDate), {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
          )
        </span>
        {isFull && (
          <span className="tw-rounded tw-bg-red-600 tw-px-2 py-1 tw-text-white">
            Program Full
          </span>
        )}
      </h2>
      <div className="tw-grid tw-grid-cols-4">
        <div>
          <Form
            key={programDate.capacity}
            init={{ capacity: programDate.capacity }}
            onSubmit={async ({ state: { inputs }, enableSubmit, setInput }) => {
              const capacity = Number(inputs.capacity);
              if (!capacity) return enableSubmit();

              await updateCapacity({
                variables: {
                  programDateId: dateId,
                  capacity: capacity,
                },
              });
              enableSubmit();
            }}
          >
            <FormContext.Consumer>
              {([_, setInput]) => {
                return (
                  <>
                    <div className="tw-flex tw-gap-2 tw-items-center">
                      <div className="tw-w-4 tw-h-4 tw-flex tw-justify-center tw-items-center tw-text-3xl tw-rounded tw-bg-gray-200 tw-p-11">
                        {programDate.currentCapacity}
                      </div>
                      <div className="tw-text-4xl">/</div>
                      <div>
                        <Input name="capacity" label="Capacity" />
                      </div>
                    </div>
                    <Submit>Update Capacity</Submit>
                    <button
                      type="button"
                      disabled={isFull}
                      className="tw-bg-red-600 tw-rounded tw-block tw-w-full tw-text-white tw-p-4 hover:tw-bg-red-800 tw-cursor-pointer disabled:tw-bg-gray-200"
                      onClick={async () => {
                        await updateCapacity({
                          variables: {
                            programDateId: dateId,
                            capacity: 0,
                          },
                        });
                      }}
                    >
                      Close Program
                    </button>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        </div>
      </div>
    </div>
  );
}
