// import { useSubscription } from '@apollo/react-hooks'
// import { SUBSCRIBE_ACCOUNT_CREATED_IN_PERSON } from '../operations/Account'
// import { useNotification } from '../hooks/notification'

export default function AccountCreatedInPerson() {
    
    // const { notifications, addNotification } = useNotification()

    // const { data, loading } = useSubscription(SUBSCRIBE_ACCOUNT_CREATED_IN_PERSON)
    
    // if (!loading && !notifications.find((notification) => notification.accountCreatedInPerson.id === data.accountCreatedInPerson.id)) {
    //     addNotification(data)
    // }
    
    return null

}