import React from 'react'

import './error.css'

export default function Error({ children }) {

    return (
        <div className='error'>{ children }</div>
    )

}