import React from 'react'
import { Link } from '@reach/router'
import './breadcrumb.css'

export default function Breadcrumb({title, right, children, style, to}) {

    const El = to ? Link : 'div'

    return (
        <El className={`breadcrumbContainer ${right ? 'breadcrumbContainer--right' : ''}`} style={ style } to={ to }>
            <div className='breadcrumb'>
                <div className='breadcrumbTitle'>{ title }</div>
                { children }
            </div>
        </El>
    )
}