import React from 'react'
import { Link } from '@reach/router'
import './nav.css'

export function NavLink(props) {
    return(
        <Link
            {...props}
            getProps={({ isCurrent }) => {
                return {
                    className: isCurrent ? 'current' : ''
                }
            }}
        />
    )
}

export function SubNav({ children }) {
    return (
        <div className='subNavContainer'>
            { children }
        </div>
    )
}